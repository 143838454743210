<b-modal v-model="modal_requirement_067" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中 -->
      <template v-if="phase === 'inProgress'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 正常終了, 完了 -->
      <template v-if="(phase === 'success') || (phase === 'complete')">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- 異常終了系 -->
      <template v-if="(phase === 'failedAxisAdjustment') || (phase === 'conditionFailed')
        || (phase === 'errorLightingDrive') || (phase === 'errorAxisAdjustment') || (phase === 'error')">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_67_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'inProgress'">
          <div class="row no-gutters">
            <div class="col-12 ">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_67_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ displayInfo }}</div>
          </div>
        </template>
        <!-- 完了 -->
        <template v-if="phase === 'complete'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_67_TXT_4`) }}</div>
          </div>
        </template>
        <!-- 軸調整失敗 -->
        <template v-if="phase === 'failedAxisAdjustment'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ displayInfo }}</div>
          </div>
        </template>
        <!-- 条件不成立エラー -->
        <template v-if="phase === 'conditionFailed'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_67_TXT_6`) }}</div>
          </div>
        </template>
        <!-- 通信エラー(点灯駆動指示) -->
        <template v-if="phase === 'errorLightingDrive'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_67_TXT_7`) }}</div>
          </div>
        </template>
        <!-- 通信エラー(軸調整指示) -->
        <template v-if="phase === 'errorAxisAdjustment'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_67_TXT_8`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'error'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" @click="onStartCancelClicked" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onStartOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 完了 -->
      <template v-if="phase === 'complete'">
        <b-button size="sm" variant="primary" @click="onCompleteOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了系 -->
      <template v-if="(phase === 'failedAxisAdjustment') || (phase === 'conditionFailed')
        || (phase === 'errorLightingDrive') || (phase === 'errorAxisAdjustment') || (phase === 'error')">
        <b-button size="sm" variant="primary" @click="onFailedOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>