<div>
  <!-- ブレーキエア抜きダイアログ -->
  <b-modal v-model="modal_work_support_120" size="md" :header-bg-variant="headerColor" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">

    <template v-slot:header>
      <div class="text-center w-100">
        <template v-if="phase === 'mode-select' ||
                        phase === 'waiting_line' ||
                        phase === 'brake_control_prohibited' ||
                        phase === 'brake_control_resumed' ||
                        phase === 'waiting_sys_conditions' ||
                        phase === 'waiting_sys_cap_reservoir_tank' ||
                        phase === 'waiting_sys_brake_fluid' ||
                        phase === 'conf_accum_boost_exec' ||
                        phase === 'conf_inboost_exec' ||
                        phase === 'seq2' ||
                        phase === 'conf_inboost_fin' ||
                        phase === 'waiting_actu'
                        ">
          {{ $t('message.common_title_confirmation') }}
        </template>
        <template v-if="phase === 'accum_down_drive' ||
                        phase === 'exec_writing' ||
                        phase === 'exec_accum_boost' ||
                        phase === 'init_air_bleed_state' ||
                        phase === 'seq1' ||
                        phase === 'exec_actu' ||
                        phase === 'check_exec_actu'
                        ">
          {{ $t('individual.common_title_execution') }}
        </template>
        <template v-if="phase === 'stop_line' ||
                        phase === 'stop_sys' ||
                        phase === 'stop_actu'
                        ">
          {{ $t('message.common_title_success') }}
        </template>
        <template v-if="phase === 'error' || phase === 'connectionFailed'">
          {{ $t('message.common_title_error') }}
        </template>
      </div>
    </template>

    <template v-slot:default>
      <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
        <div class="container-fluid" style="min-height: 140px;">
          <!-- 機能選択画面 -->
          <template v-if="phase === 'mode-select'">
            <div class="row no-gutters">
              <div class="col-12">
                <b-form-select class="form-control" v-model="selectedMode" :options="modeList" @change="handleSelected"
                  :select-size="6"></b-form-select>
              </div>
            </div>
          </template>
          <!-- 待機画面(ブレーキラインエア抜き) -->
          <template v-if="phase === 'waiting_line'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_1`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- ブレーキ制御禁止画面 -->
          <template v-if="phase === 'brake_control_prohibited'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_2`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- ブレーキ制御再開画面 -->
          <template v-if="phase === 'brake_control_resumed'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_3`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- アキュムレータダウン駆動 -->
          <template v-if="phase === 'accum_down_drive'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    timerInfo }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 停止画面(ブレーキラインエア抜き) -->
          <template v-if="phase === 'stop_line'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_5`) }}</div>
                </div>
              </div>
            </div>
          </template>

          <!-- 待機画面1(ブレーキシステムエア抜き) -->
          <template v-if="phase === 'waiting_sys_conditions'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_7`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 待機画面2(ブレーキシステムエア抜き) -->
          <template v-if="phase === 'waiting_sys_cap_reservoir_tank'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_8`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 待機画面3(ブレーキシステムエア抜き) -->
          <template v-if="phase === 'waiting_sys_brake_fluid'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_9`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 実行確認画面(アキュムレータ・ブースター間エア抜き) -->
          <template v-if="phase === 'conf_accum_boost_exec'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_10`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- エア抜き状態初期化画面(アキュムレータ・ブースター間エア抜き) -->
          <template v-if="phase === 'init_air_bleed_state'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_11`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 実行画面(アキュムレータ・ブースター間エア抜き) -->
          <template v-if="phase === 'exec_accum_boost'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    timerInfo }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 実行確認画面(ブースター内エア抜き) -->
          <template v-if="phase === 'conf_inboost_exec'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_13_0002`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- エア抜きシーケンス1画面(ブースター内エア抜き) -->
          <template v-if="phase === 'seq1'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    timerInfo }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- エア抜きシーケンス2画面(ブースター内エア抜き) -->
          <template v-if="phase === 'seq2'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    seq2Info }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 完了確認画面(ブースター内エア抜き) -->
          <template v-if="phase === 'conf_inboost_fin'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_16`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 停止画面(ブレーキシステムエア抜き) -->
          <template v-if="phase === 'stop_sys'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_17`) }}</div>
                </div>
              </div>
            </div>
          </template>

          <!-- 待機画面(アクチュエータ強制リフレッシュ) -->
          <template v-if="phase === 'waiting_actu'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_19`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 実行画面(アクチュエータ強制リフレッシュ) -->
          <template v-if="phase === 'exec_actu'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_11`) }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 実行監視(アクチュエータ強制リフレッシュ)画面 -->
          <template v-if="phase === 'check_exec_actu'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    timerInfo }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 停止画面(アクチュエータ強制リフレッシュ) -->
          <template v-if="phase === 'stop_actu'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_21_0002`) }}</div>
                </div>
              </div>
            </div>
          </template>

          <!-- エラー -->
          <template v-if="phase === 'error'">
            <div class="row no-gutters">
              <div v-if="selectedMode === 'line'" class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_6`) }}</div>
                </div>
              </div>
              <div v-if="selectedMode === 'sys'" class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_18`) }}</div>
                </div>
              </div>
              <div v-if="selectedMode === 'actu'" class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t(`${i18nWsKey}.REQ_WS_120_TXT_22`) }}</div>
                </div>
              </div>
            </div>
          </template>

          <!-- 共通エラー画面 -->
          <template v-if="phase === 'connectionFailed'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t('individual.error_communication_error') }}</div>
            </div>
          </template>

        </div>
      </b-overlay>
    </template>

    <template v-slot:footer>
      <div class="w-100 text-center ">

        <!-- 機能選択画面 -->
        <template v-if="phase === 'mode-select'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- OKボタン表示 -->
          <b-button size="sm" variant="primary" @click="selectMode" class="mx-2" :disabled="isNotSelected">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- 待機画面(ブレーキラインエア抜き) -->
        <template v-if="phase === 'waiting_line'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stopProcess" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="executeLine" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- ブレーキ制御禁止画面 -->
        <template v-if="phase === 'brake_control_prohibited'">
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="stopLine" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- ブレーキ制御再開画面 -->
        <template v-if="phase === 'brake_control_resumed'">
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="readAccum" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 停止画面(ブレーキラインエア抜き) -->
        <template v-if="phase === 'stop_line'">
          <!-- OKボタン表示 -->
          <b-button size="sm" variant="primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- 待機画面1(ブレーキシステムエア抜き) -->
        <template v-if="phase === 'waiting_sys_conditions'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="toCapReservoirTank" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 待機画面2(ブレーキシステムエア抜き) -->
        <template v-if="phase === 'waiting_sys_cap_reservoir_tank'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="toBrakeFluid" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 待機画面3(ブレーキシステムエア抜き) -->
        <template v-if="phase === 'waiting_sys_brake_fluid'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="toExecutionConfirmation" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 実行確認画面(アキュムレータ・ブースター間エア抜き) -->
        <template v-if="phase === 'conf_accum_boost_exec'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="executeProcessInit" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 実行確認画面(ブースター内エア抜き) -->
        <template v-if="phase === 'conf_inboost_exec'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="executeProcessInBoost" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- エア抜きシーケンス1画面(ブースター内エア抜き) -->
        <template v-if="phase === 'seq1'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="executeBreakProcess" class="mx-2" :disabled="isCancel">
            {{ $t('message.common_button_cancel') }}
          </b-button>
        </template>

        <!-- エア抜きシーケンス2画面(ブースター内エア抜き) -->
        <template v-if="phase === 'seq2'">
          <!-- OKボタン表示 -->
          <b-button size="sm" variant="primary" @click="toSeq1OrConf" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- エア抜き完了確認画面(ブースター内エア抜き) -->
        <template v-if="phase === 'conf_inboost_fin'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_polling_alert_terminate') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="toWaitingLine" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 停止画面(ブレーキシステムエア抜き) -->
        <template v-if="phase === 'stop_sys'">
          <!-- 終了ボタン表示 -->
          <b-button size="sm" variant="primary" @click="stop" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_polling_alert_terminate') }}
          </b-button>
        </template>

        <!-- 待機画面(アクチュエータ強制リフレッシュ) -->
        <template v-if="phase === 'waiting_actu'">
          <!-- キャンセルボタン表示 -->
          <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <!-- 次へボタン表示 -->
          <b-button size="sm" variant="primary" @click="executeProcessActu" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </template>

        <!-- 停止画面(アクチュエータ強制リフレッシュ) -->
        <template v-if="phase === 'stop_actu'">
          <!-- OKボタン表示 -->
          <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2" :disabled="isLoading">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- エラー -->
        <template v-if="phase === 'error'">
          <div v-if="selectedMode === 'line'">
            <!-- OKボタン表示 -->
            <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2" :disabled="isLoading">
              {{ $t('message.common_button_ok') }}
            </b-button>
          </div>
          <div v-if="selectedMode === 'actu' || selectedMode === 'sys'">
            <!-- OKボタン表示 -->
            <b-button size="sm" variant="primary" @click="stop" class="mx-2" :disabled="isLoading">
              {{ $t('message.common_button_ok') }}
            </b-button>
          </div>
        </template>

        <!-- 共通エラー画面 -->
        <template v-if="phase === 'connectionFailed'">
          <b-button size="sm" variant="primary" @click="exit" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

      </div>
    </template>
  </b-modal>
  <!-- 実行用別モーダル -->
  <component v-if="currentWizard" @close-wizard="closeWizard" :i18nWsKey="ws120i18nWsKey"
    :workSupportId="ws120workSupportId" :is="currentWizard" @isReturn="ws120Return" :menuTitle="isMenuTitle">
  </component>
</div>