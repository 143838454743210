<b-modal v-model="modal_requirement_101" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' || phase === 'operationConfirmation' || phase === 'reOperationConfirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'reRegisterReserve' || phase === 'reRegister'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'negativeResponse' || phase === 'nonResponse' || phase === 'conditionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 確認表示 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_1`) }}</div>
          </div>
        </template>
        <!-- IG ON操作表示 -->
        <template v-if="phase === 'operationConfirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_2`) }}</div>
          </div>
        </template>
        <!-- IG ON再操作表示 -->
        <template v-if="phase === 'reOperationConfirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_3`) }}</div>
          </div>
        </template>
        <!-- LAN接続ユニット再登録準備 -->
        <template v-if="phase === 'reRegisterReserve'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_4`) }}</div>
          </div>
        </template>
        <!-- LAN接続ユニット再登録 -->
        <template v-if="phase === 'reRegister'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 成功表示 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_6`) }}</div>
          </div>
        </template>
        <!-- 否定応答表示 -->
        <template v-if="phase === 'negativeResponse'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
          </div>
        </template>
        <!-- 無応答表示 -->
        <template v-if="phase === 'nonResponse'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_8`) }}</div>
          </div>
        </template>
        <!-- 条件未成立表示 -->
        <template v-if="phase === 'conditionFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_101_TXT_9`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 確認表示 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="moveOperationConfirmationEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- IG ON操作表示, IG ON再操作表示 -->
      <template v-if="phase === 'operationConfirmation' || phase === 'reOperationConfirmation'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="readProcessEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 成功表示, 各エラー画面 -->
      <template v-if="phase === 'success' || phase === 'negativeResponse' || phase === 'nonResponse' || phase === 'conditionFailed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>