<!-- ブレーキエア抜きダイアログ -->
<b-modal v-model="modal_work_support_124" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">

  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'waiting' ||
                      phase === 'execution'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'monitoring'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'stop'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'error' || phase === 'connectionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'waiting'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_124_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行画面 -->
        <template v-if="phase === 'execution'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_124_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行監視画面 -->
        <template v-if="phase === 'monitoring'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_124_TXT_3_0001`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 停止画面 -->
        <template v-if="phase === 'stop'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_124_TXT_4_0002`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- エラー画面 -->
        <template v-if="phase === 'error'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_124_TXT_5`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 通信エラー画面 -->
        <template v-if="phase === 'connectionFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.error_communication_error') }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 待機画面 -->
      <template v-if="phase === 'waiting'">
        <!-- キャンセルボタン表示 -->
        <b-button size="sm" variant="outline-primary" @click="stopProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- 次へボタン表示 -->
        <b-button size="sm" variant="primary" @click="executeProcess" class="mx-2">
          {{ $t('message.button_agreement_confirm') }}
        </b-button>
      </template>

      <!-- 実行画面 -->
      <template v-if="phase === 'execution'">
        <!-- キャンセルボタン表示 -->
        <b-button size="sm" variant="outline-primary" @click="stopProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- 次へボタン表示 -->
        <b-button size="sm" variant="primary" @click="displayWait" class="mx-2">
          {{ $t('message.button_agreement_confirm') }}
        </b-button>
      </template>

      <!-- 停止画面 -->
      <template v-if="phase === 'stop'">
        <!-- OKボタン表示 -->
        <b-button size="sm" variant="primary" @click="turnPage" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- エラー画面 -->
      <template v-if="phase === 'error'">
        <!-- OKボタン表示 -->
        <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 通信エラー画面 -->
      <template v-if="phase === 'connectionFailed'">
        <b-button size="sm" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>