<b-modal
  v-model="modal_special_requirement_ABG2"
  size="lg"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'input'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t(`message.common_title_success`) }}
      </template>
      <template v-if="phase === 'didFailed' ||
                      phase === 'failed'">
        {{ $t(`message.common_title_error`) }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- DID入力指示 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
                      v-html="$t(`individual.label_start_ABG2`, [dataId])"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- DID入力 -->
        <template v-if="phase === 'input'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_data_id_ABG2`) }}</div>
                <b-form-input type="text" v-model="inputData" autocomplete="off" size="sm" maxlength="4" :state="isAlert.id.valid"/>
                <b-form-invalid-feedback>{{ $t(isAlert.id.msg) }}</b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </template>
        <!-- DIDデータ表示 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`individual.label_did_data_ABG2`, [didData]) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- DIDエラー表示 -->
        <template v-if="phase === 'didFailed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_did_error_ABG2')"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- DID入力指示 -->
      <template v-if="phase === 'start'">
        <div class="w-100 text-right ">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="showInput" class="mx-2 mb-2 btn-width-sm rounded-pill">
            {{ $t(`individual.label_input`) }}
          </b-button>
        </div>
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_cancel') }}
        </b-button> 
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="readDIDData" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- DID入力 -->
      <template v-if="phase === 'input'">
        <div class="w-100 text-right ">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="inputClearProcess" class="mx-2 mb-2 btn-width-sm rounded-pill">
            {{ $t('message.common_button_clear') }}
          </b-button>
        </div>
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="returnStartProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_cancel') }}
        </b-button> 
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="inputOkProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- DIDデータ表示 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="returnStartProcess" class="mx-2">
          {{ $t('message.common_button_back') }}
        </b-button> 
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
      </template>
      <!-- DIDエラー表示 -->
      <template v-if="phase === 'didFailed'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitProcess" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button> 
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="returnStartProcess" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>