import api from '../../common/api';
import handler from '../../share/util/apiResultValidator';
import config from '../../share/config';


export default {
  namespaced: true,

  state: {
    vehicleType: "",
    menuList: [],
    isImbSupport: false
  },

  mutations: {
    setImbMenu(state, payload) {
      let mune = [];
      let vehicleType = '';
      let support = false;

      if (payload.menuDetails && payload.menuDetails.menu_list) {
        mune = payload.menuDetails.menu_list;
      }

      if (payload.vehicle_type) {
        vehicleType = payload.vehicle_type;
        support = true;
      }

      state.menuList = mune;
      state.vehicleType = vehicleType;
      state.isImbSupport = support;
    },

  },

  actions: {
    async getImbMenu(context, arg) {
      // Response結果をオブジェクトに入れる
      const getImbResponse = {
        errorResult: null
      };
      if (arg.netAppId !== "" && !arg.isTestMode) {
        const endPoint = '/imbMenu/' + arg.netAppId;
        const imbMenuResponse = await api.getCall(config.IMB, endPoint);
        handler.validate(handler.validateTypes.all, imbMenuResponse, null, null, () => {
          context.commit('setImbMenu', imbMenuResponse.data);
        }, (result) => {
          // 処理が失敗してる場合
          getImbResponse.errorResult = result;
        }, null, false
        );
        return getImbResponse;
      }
      // netAppIdが空の場合、エラーにはせずIMB非対応で返す
      else {
        context.commit('setImbMenu', {"menuDetails": null, "vehicle_type": null});
        return getImbResponse;
      }
    }
  }
};
