<div class="container">
  <div class="change-unit-container">
    <div class="row row-90">
      <div class="col-12 text-center">
        <label class="label-sub-header">{{ $t('message.header_sub_change_unit') }}</label>
        <hr class="hr-line-100">
      </div>

      <div class="col-12 text-left" v-for="unitSetting in unitSettings">
        <div class="col-12 text-left mt-4">
          <label class="label-sm ml-3">{{ $t(unitSetting.name) }}</label>
        </div>
        <div class="col-12 text-left">
          <b-form-radio v-model="unitSetting.selected" v-for="item in unitSetting.items" v-bind:key="item.value"
            :value="item.value" inline class="ml-3">{{ $t(item.text) }}
          </b-form-radio>
        </div>
      </div>

      <div class="col-12 text-center mb-4 mt-3">
        <b-button variant="outline-primary" v-on:click="backToPrevious()">
          {{ $t('message.common_button_back') }}
        </b-button>
        <b-button variant="primary" class="ml-4" v-on:click="didTapChangeUnit()">
          {{ $t("message.common_button_update") }}
        </b-button>
      </div>
    </div>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>