<!-- 強制S除去ダイアログ -->

<!-- ********** ヘッダ ********** -->
<b-modal v-model="modal_requirement_009" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- ヘッダ：確認／色：secondary -->
      <template v-if="phase === 'start' || 
              phase === 'startRemoving' || 
              phase === 'stopRemoving' || 
              phase === 'coolingStop' || 
              phase === 'complete'">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- ヘッダ：実行中／色：secondary -->
      <template v-if="phase === 'removing'">
        {{ $t('individual.common_title_execution') }}
      </template>

      <!-- ヘッダ：成功／色：success -->
      <template v-if="phase === 'firstSuccess' ||
                      phase === 'coolingSuccess'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- ヘッダ：エラー／色：danger -->
      <template v-if="phase === 'unsuitable' ||
            phase === 'coolingFailed' ||
            phase === 'coolingCommunicationError' ||
            phase === 'connectionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <b-form-select class="form-control" v-model="selectedMode" :options="modeList"
              :select-size="6"></b-form-select>
            <div>{{ $t(`${i18nWsKey}.REQ_WS_9_TXT_1`) }}</div>
          </div>
        </template>

        <!-- 実行（停車／走行注意表示） -->
        <template v-if="phase === 'startRemoving'">
          <div class="row no-gutters">
            <div v-if="selectedMode === 'stop'" class="work-support-message" style="white-space: break-spaces">{{
              $t(`${i18nWsKey}.REQ_WS_9_TXT_2`) }}</div>
            <div v-if="selectedMode === 'run'" class="work-support-message" style="white-space: break-spaces">{{
              $t(`${i18nWsKey}.REQ_WS_9_TXT_3`) }}</div>
          </div>
        </template>

        <!-- 実行監視：除去状態確認 -->
        <template v-if="phase === 'removing'">
          <div class="row no-gutters">
            <div class="work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_9_TXT_4`) }}</div>
          </div>
        </template>

        <!-- 実行監視初回：正常終了 -->
        <template v-if="phase === 'firstSuccess'">
          <div class="row no-gutters">
            <div class="work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_9_TXT_6_0002`) }}</div>
          </div>
        </template>

        <!-- 実行監視：中止確認 -->
        <template v-if="phase === 'stopRemoving'">
          <div class="row no-gutters">
            <div class="work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_9_TXT_5`) }}</div>
          </div>
        </template>

        <!-- 冷却中表示（正常終了） -->
        <template v-if="phase === 'coolingSuccess'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ replaceMessage }}</div>
          </div>
        </template>

        <!-- 冷却中表示（異常終了） -->
        <template v-if="phase === 'coolingFailed'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ replaceMessage }}</div>
          </div>
        </template>

        <!-- 冷却中表示（通信エラー） -->
        <template v-if="phase === 'coolingCommunicationError'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ replaceMessage }}</div>
          </div>
        </template>

        <!-- 冷却中表示（中止） -->
        <template v-if="phase === 'coolingStop'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ replaceMessage }}</div>
          </div>
        </template>

        <!-- 条件不成立 -->
        <template v-if="phase === 'unsuitable'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{
              $t(`${i18nWsKey}.REQ_WS_9_TXT_12_${resultCode}`) }}</div>
          </div>
        </template>

        <!-- 完了 -->
        <template v-if="phase === 'complete'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ $t(`${i18nWsKey}.REQ_WS_9_TXT_11`) }}
            </div>
          </div>
        </template>

        <!-- 共通エラー -->
        <template v-if="phase === 'connectionFailed'">
          <div class="row no-gutters">
            <div class="work-support-message">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 待機 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="clickShowStartRemoving" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 実行（停車／走行注意表示）-->
      <template v-if="phase === 'startRemoving'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickFirstExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 実行監視：除去状態確認 -->
      <template v-if="phase === 'removing'">
        <b-button size="sm" variant="outline-primary" @click="clickShowStop" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>

      <!-- 実行監視：中止 -->
      <template v-if="phase === 'stopRemoving'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="clickRemoving" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickCoolingStop" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 初回正常終了 -->
      <!-- 初回条件不成立 -->
      <!-- 完了 -->
      <template v-if="phase === 'firstSuccess' || 
                      phase === 'unsuitable' ||
                      phase === 'complete'">
        <b-button size="sm" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 共通エラー -->
      <template v-if="phase === 'connectionFailed'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>
</b-modal>