<b-modal v-model="modal_requirement_138" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 実行中 -->
      <template v-if="phase === 'indexAcquisition' || phase === 'dataAcquisition'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 確認 -->
      <template v-if="phase === 'indexDisplay'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 成功 -->
      <template v-if="phase === 'dataResultsDisplay' || phase === 'dataNonExistence'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー -->
      <template v-if="phase === 'acquisitionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>
  <!-- 文言表示 -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- Index取得 -->
        <template v-if="phase === 'indexAcquisition'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">
                  {{ $t(`${i18nWsKey}.REQ_WS_138_TXT_1`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- ログデータ取得 -->
        <template v-if="phase === 'dataAcquisition'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">
                  {{ $t(`${i18nWsKey}.REQ_WS_138_TXT_2`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Index表示画面 -->
        <template v-if="phase === 'indexDisplay'">
          <div style="max-height: 265px; overflow-y: auto;" class="overflow-y: scroll bottom">
            <div>
              <div class="row no-gutters" ref="tableHeader">
                <div class="col-auto">
                  <slot name="subButtonContainer"></slot>
                </div>
                <div class="col pagination justify-content-end tabs-bottom">
                  <slot name="contentRightEnd"></slot>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-sm overflow-auto simple-table">
                  <tr v-if="columns">
                    <th v-for="(column, i) in columns" :key="i">
                      <span>{{ $t(column.label) }}</span>
                    </th>
                  </tr>
                  <tr v-for="(data, index) in (items)" :key="index">
                    <td v-for="(column, i) in columns" :key="i" v-bind:class="getTdStyle(data.bgStyle, index)">
                      <span v-bind:class="getColumnClasses(data, column.name)" v-html="data[column.name]">
                      </span>
                      <div v-if="column.useCheckbox">
                        <input type="checkbox" @click="checkboxClicked(index)" v-model="isChecked[index]"
                          v-bind:disabled="isDisabled[index]"
                          v-bind:class="isDisabled[index]?'checkbox-disabled':'checkbox'" />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </template>
        <!-- ログデータあり表示 -->
        <template v-if="phase === 'dataResultsDisplay'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="work-support-message">
                  <label class="topleft">
                    {{ $t(`${i18nWsKey}.REQ_WS_138_TXT_4`) }}
                  </label>
                  <div v-for="data in checkedNumList" :key="data.resultDisplayNum">
                    {{ $t(`${i18nWsKey}.label_log_number`, [data.resultDisplayNum]) }}
                    <a v-if="data.upload_status === 'completed'">
                      {{ $t('message.common_title_success') }}
                    </a>
                    <a v-else>
                      {{ $t('individual.status_customize_result_failure')}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- ログデータなし表示 -->
        <template v-if="phase === 'dataNonExistence'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">
                  {{ $t(`${i18nWsKey}.REQ_WS_138_TXT_3`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'acquisitionFailed'">
          <div class="w-100">
            <div class="work-support-message">
              {{ $t(`${i18nWsKey}.REQ_WS_138_TXT_5`) }}
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>
  <!-- フッター -->
  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- Index表示 -->
      <template v-if="phase === 'indexDisplay'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || isButtonDisabled" variant="primary" @click="executeReadLog"
          class="mx-2">
          {{ $t(`${i18nWsKey}.button_acquisition`) }}
        </b-button>
      </template>
      <!-- ログデータあり画面 -->
      <template v-if="phase === 'dataResultsDisplay'">
        <b-button size="sm" :disabled="isLoading || downloadDisabled" variant="outline-primary" @click="downloadFile" class="mx-2">
          {{ $t('message.button_vehicle_system_download_zip') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- ログデータなし表示画面 -->
      <template v-if="phase === 'dataNonExistence'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー -->
      <template v-if="phase === 'acquisitionFailed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>