<b-modal
  v-model="modal_requirement_066"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop
  no-close-on-esc
  centered
  no-stacking
>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'"> </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="w-100 mb-4">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_show_vehicle_information`) }}</div>
          </div>
          <div class="row no-gutters">
            <div class="w-100">
              <div
                style="max-height: 265px; overflow-y: auto; "
              >
                <!-- テーブル部品 -->
                <cstm-table
                  id="cstm-table"
                  useStripeStyle
                  hasTabs
                  useSort
                  initSortDir="asc"
                  currentSortkey="itemName"
                  :items="bodyItems"
                  :columns="systemTableColumns"
                  type="simple"
                >
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div
              class="box-margin-right work-support-message"
              style="white-space: break-spaces;"
            >{{ $t(`individual.error_communication_error`) }}
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button
          size="sm"
          variant="primary"
          @click="endProcessing"
          class="mx-2"
        >
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了 -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="endProcessing" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>
