<div class="col-12">
  <div class="col-12 data-monitor-page">
    <div class="one-liner-btns">
      <!-- メモボタン -->
      <div class="left-side-btn">
        <b-button class="ml-2" variant="primary" size="sm" @click="parentOpenMemoModal">
          {{ $t('message.button_vehicle_dtc_note_modal') }}
        </b-button>
      </div>
      <!-- グラフ設定ボタン -->
      <div class="right-side-btn">
        <b-button
          class="ml-2"
          variant="primary"
          size="sm"
          @click="handleConfigureGraph"
          :disabled="btnDisabled"
        >
          {{ $t('individual.button_data_monitor_graph_config') }}
        </b-button>
      </div>
    </div>

    <!-- 再生データのリストやグラフ -->
    <data-monitor
      class="{'dm-area': showSelect}"
      ref="data_monitor"
      :showSelect="false"
      :inPlaying="inPlaying"
      :inExecution="false"
      @toggle-playing="togglePlaying"
      @change-check-value = "changeCheckValue"
      @back-select="backSelect"
    />
  </div>
  <processing-view :processing="processing"></processing-view>
</div>