<b-modal v-model="modal_requirement_081" size="lg" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'wait'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success' || phase === 'display'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'settingfailed' || phase === 'displayfailed' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'wait'"> </template>
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              subscriptionStatusMessage }}</div>
          </div>
        </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_81_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 結果表示 -->
        <template v-if="phase === 'display'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              subscriptionStatusMessage }}</div>
          </div>
        </template>
        <!-- 設定失敗表示 -->
        <template v-if="phase === 'settingfailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_81_TXT_4`) }}</div>
          </div>
        </template>
        <!-- 取得失敗表示 -->
        <template v-if="phase === 'displayfailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_81_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 異常終了 -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <div class="d-flex flex-wrap justify-content-center">
          <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="onStartCancelClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStartOnClicked" class="mt-2 mr-2">
            {{ $t('individual.label_button_on') }}
          </b-button>
          <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStartOffClicked" class="mt-2 mr-2">
            {{ $t('individual.label_button_off') }}
          </b-button>
        </div>
      </template>
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 結果表示 -->
      <template v-if="phase === 'display'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onDisplayOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 設定失敗表示 -->
      <template v-if="phase === 'settingfailed'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onSettingFailedOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 取得失敗表示 -->
      <template v-if="phase === 'displayfailed'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onDisplayFailedOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了 -->
      <template v-if="phase === 'error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>