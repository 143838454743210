import individualDiagnose from "../../../share/util/individualDiagnose";

const TSV_CONTENT_TYPE = 'text/tab-separated-values;charset=utf-8;';

// 値の翻訳を実施するデータタイプ
export const VALUE_TRANSLATE_TYPE = ['FLG', 'HEX', 'decExStr'];
// 値の10進数変換を実施するデータタイプ
export const VALUE_CONVERT_DECIMAL_TYPE = [
  'Bin', 'min2hhmm', 'sec2hhmm', 'sec2hhmmss',
  'Lat_DEG', 'Lng_DEG', 'Lat_DMM', 'Lng_DMM', 'LatLng_DMM'
];
// Worker種別
export const WORKER = Object.freeze({
  REALTIME: 'realtime',
  RESTFUL: 'restful'
});
// 親スレッドからWorkerに通知するメッセージ種別
export const WORKER_TASK = Object.freeze({
  START: 'start',
  STOP: 'stop'
});
// Workerから親スレッドに返されるメッセージ種別
export const WORKER_EVENT = Object.freeze({
  SUCCESS_CONNECTION: 'success_connection',
  BASE_TIME: 'baseTime',
  CHUNK_DATA: 'chunkData',
  MEASUREMENT_INFO: 'measurementInfo',
  SUCCESS_END: 'success_end',
  START_ERROR: 'start_error',
  RUNTIME_ERROR: 'runtime_error',
});

/**
 * TSVエクスポート処理
 * @param {Array} data TSVに出力するオブジェクトの配列。先頭オブジェクトのキー名をTSVのヘッダー項目にする。
 * @param {String} fileName ファイル名
 */
export function tsvFileExport(data, fileName) {
  if (!data || data.length === 0) {
    return;
  }

  const tsvData = convertToTSV(data);
  const blob = new Blob([tsvData], { type: TSV_CONTENT_TYPE });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

/**
 * TSVファイル変換処理
 */
function convertToTSV(data) {
  const tsvArray = [];
  const header = Object.keys(data[0]).map(key => `${key}`).join('\t');
  tsvArray.push(header);
  data.forEach(item => {
    const row = Object.values(item).map(value => `${value}`.replace('\n', '\\n').replace('\t', '\\t')).join('\t');
    tsvArray.push(row);
  });
  return tsvArray.join('\n');
}

/**
 * 物理変換された値から表示する値を取得する
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {string} did 選択項目のDID情報
 * @param {string} value 変換された計測値
 * @param {array} conversionInfo 変換情報リスト
 * @param {Array[object]} menuList 個別診断メニューのリスト
 * @param {String} specifiedSystemId 任意のECUの翻訳キーを取得したい場合のみ指定
 * @returns 翻訳された計測値
 */
export function getTranslateValue(instance, did, value, conversionInfo, menuList, specifiedSystemId = null) {
  // "DID情報_変換結果"（翻訳用jsonを検索する文字列）
  const didValKey = did + "_" + value;

  // diagのjson参照用キー
  const findFFDi18nKey = individualDiagnose.getFFDi18nKey(instance, menuList, specifiedSystemId);
  const didConversionKey = `${findFFDi18nKey}.`;
  let didValDisp = "";

  // diagに存在する場合
  if (instance.$te(didConversionKey + didValKey)) {
    // diagから翻訳結果を取得
    didValDisp = instance.$t(didConversionKey + didValKey);
    // diagに存在しない場合
  } else {
    // decExStrの場合、翻訳結果が無ければ空を返す
    if (conversionInfo.data_type === "decExStr") {
      return "";
    }
    const defaultKey = did + "_default";
    if (instance.$te(didConversionKey + defaultKey)) {
      didValDisp = instance.$t(didConversionKey + defaultKey);
      // "{0]"が含まれる場合、置換する
      didValDisp = didValDisp.replace(/\{0\}/, value);
    } else {
      // それ以外の場合、生値を返却
      return value;
    }
  }

  // diagから翻訳結果を返却
  return didValDisp;
}

export default {
  VALUE_TRANSLATE_TYPE,
  VALUE_CONVERT_DECIMAL_TYPE,
  tsvFileExport,
  getTranslateValue,
};