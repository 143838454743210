<!-- 開発用HEX モード -->
<b-modal v-model="modal_special_requirement_ECM4" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  :hide-footer="isHideFooter"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">
      {{ $t(headerTitle) }}
    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 実行画面 -->
        <template v-if="phase === 'execute'">
          <cstm-table id="cstm-table" useStripeStyle hasTabs type="simple"
            rowSelect @dataClicked="clickCell" :items="addrDatBitList" :columns="addrDatBitColumns">
          </cstm-table>
        </template>

        <!-- アドレス入力画面 -->
        <template v-if="phase === 'addressInput'">
          <div class="work-support-message">
            <b-form-input type="text" v-model="addressInputData"
              autocomplete="off" size="sm" maxlength="8" :state="isAlert.data.valid"/>
            <b-form-invalid-feedback>{{ $t(isAlert.data.msg) }}</b-form-invalid-feedback>
          </div>
        </template>

        <!-- データ入力画面 -->
        <template v-if="phase === 'dataInput'">
          <div class="work-support-message">
            <b-form-input type="text" v-model="dataInputData"
              autocomplete="off" size="sm" maxlength="2" :state="isAlert.data.valid"/>
            <b-form-invalid-feedback>{{ $t(isAlert.data.msg) }}</b-form-invalid-feedback>
          </div>
        </template>

        <!-- 通信エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">
            <div style="white-space: break-spaces" v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>

      </div>
    </b-overlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center">

      <!-- アドレス入力画面 -->
      <template v-if="phase === 'addressInput'">
        <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="eventExecute" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="clickAddressInputExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- データ入力画面 -->
      <template v-if="phase === 'dataInput'">
        <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="eventExecute" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="clickDataInputExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 通信エラー画面 -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>

</b-modal>