<b-modal v-model="modal_requirement_116" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- アプライドモデル・オプションコード入力表示 -->
      <template v-if="phase === 'inputData'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'stop'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- アプライドモデル未定義表示, アプライドモデル異常表示, 異常終了(エラー) -->
      <template v-if="phase === 'undefinedError' || phase === 'abnormalityDisplay' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- アプライドモデル・オプションコード入力表示 -->
        <template v-if="phase === 'inputData'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="w-100 mb-4">
                <div class="text-center work-support-message">{{ 
                  $t('individual.label_input_applied_op') }}
                </div>
                <table class="box-margin-right box-margin-top work-support-message" style="width:400px;">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">{{ 
                        $t(`${i18nWsKey}.REQ_WS_116_TXT_2`) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="appliedModel"
                            :state="validateAppliedModel && !validateAppliedModel.valid" type="text" size="sm"
                            autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateAppliedModel">{{ 
                            validateAppliedModel.msg }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top">{{ 
                        $t(`${i18nWsKey}.REQ_WS_116_TXT_4`) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="optionCode" :state="validateOptionCode && !validateOptionCode.valid"
                            type="text" size="sm" autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateOptionCode">{{ 
                            validateOptionCode.msg }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="work-support-message"  style="white-space: break-spaces;" v-html="$t(`individual.message_input_option_code_information`)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 停止 -->
        <template v-if="phase === 'stop'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_116_TXT_5`) }}</div>
          </div>
        </template>
        <!-- アプライドモデル未定義表示 -->
        <template v-if="phase === 'undefinedError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_116_TXT_6`) }}</div>
          </div>
        </template>
        <!-- アプライドモデル異常表示 -->
        <template v-if="phase === 'abnormalityDisplay'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_116_TXT_7`) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- アプライドモデル・オプションコード入力表示 -->
      <template v-if="phase === 'inputData'">
        <b-button size="sm" variant="outline-primary" @click="exit" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onInputOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'stop'">
        <b-button size="sm" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- アプライドモデル未定義表示, 異常終了, 停止 -->
      <template v-if="phase === 'undefinedError' || phase === 'abnormalityDisplay' || phase === 'failed'">
        <b-button size="sm" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>