import api from '../../common/api';
import util from '../../share/util/utils';
import handler from '../../share/util/apiResultValidator';
import config from '../../share/config';
import resource from '../../share/util/resourceMap';
const publishedUnitListTestMode = require('../../data/testMode/publishedUnits.json');

export default {
  data() {
    return {

    };
  },
  namespaced: true,

  state: {
    // リプロ機能向けシステム一覧テーブル情報
    systemTable: null,
    // リプロ機能テーブルでのダイアログ表示制御用フラグ
    showSystemTblModal: true,
    // 診断情報一覧データ
    allDtcSystems: null,
    // リプロサポート対象有無フラグ
    isReproSupport: false,
    // カスタム情報一覧取得
    customizeSystems: null,
  },

  mutations: {
    setNativeSystemTable(state, payload) {
      state.systemTable = payload;

      let isSupport = false;
      if (payload && payload.unpublished && payload.unpublished.length !== 0) {
        isSupport = true;
      }
      if (payload && payload.published && payload.published.length !== 0) {
        isSupport = true;
      }
      state.isReproSupport = isSupport;
    },
    setSystemTblModalFlag(state, payload) {
      state.showSystemTblModal = payload;
    },
    setAllDtcTable(state, payload) {
      state.allDtcSystems = payload;
    },

    /**
     * 個別システムの DTC 情報を更新する
     * @param {object} state State
     * @param {string} payload.system 更新対象のシステム
     * @param {object} payload.data 更新対象のシステムの全データ
     */
    setIndividualDtcData(state, payload) {
      // ALL DTCのstateが存在しない場合、return
      if (!state.allDtcSystems) {
        return;
      }

      // 現在の state を全コピー
      const currentAllDtcSystems = Object.assign({}, state.allDtcSystems);

      // 上書き予定のシステムの先頭indexを取得する
      const targetFirstIndex = currentAllDtcSystems.data.findIndex(dtc => dtc.system === payload.system);
      
      // 全ダイアグコード取得⇒個別診断のダイアグコード取得の際、
      // 後ろに個別診断のダイアグコード結果を結合すると、全ダイアグコードに戻った際に順番が入れ替わってしまう。
      // これを避けるため、 対象システムのデータをクリアし、削除した位置に要素に挿入している。
      currentAllDtcSystems.data = currentAllDtcSystems.data.filter(dtc => dtc.system !== payload.system);
      // 先頭のindexが存在する場合、その位置にデータを結合する
      if (targetFirstIndex !== -1) {
        payload.data.forEach((dtc, index) => {
          currentAllDtcSystems.data.splice(targetFirstIndex + index, 0, dtc);
        });
      } else {
        // 該当のindexが存在しない場合、配列の後ろに結合する
        currentAllDtcSystems.data = currentAllDtcSystems.data.concat(payload.data);
      }

      // state を更新
      state.allDtcSystems = currentAllDtcSystems;
    },

    setCustomizeTable(state, payload) {
      state.customizeSystems = payload;
    },
  },

  actions: {
    // DTCテーブル一覧情報を取得
    async getDtcSystemTable(context, arg) {
      // Response結果をオブジェクトに入れる
      const getDtcResponse = {
        errorResult: null
      };
      if (arg.projectId && arg.measureId) {
        const endPoint = '/diagDtc/' + arg.projectId + '/' + arg.measureId;
        const res = await api.getCall(config.DIAG, endPoint, arg.operationScreen);
        handler.validate(
          handler.validateTypes.all,
          res, null, null,
          () => {
            if (!res.data.clear_dtc_all_system_count && res.data.clear_dtc_all_system_count !== 0) {
              // clear_dtc_all_system_countが無かった場合
              res.data.clear_dtc_all_system_count = -1;
            }
            // 処理が成功したときデータを入れる
            context.commit('setAllDtcTable', res.data);
          },
          (result) => {
            // 処理が失敗してる場合
            getDtcResponse.errorResult = result;
          }, null, false);
        return getDtcResponse;
      }
      // measureIdが取れ無かった場合、エラーにはせず空配列で返す
      else {
        context.commit('setAllDtcTable', { clear_dtc_all_system_count: -1, data: [] });
        return getDtcResponse;
      }

    },

    // テーブルを取得
    async setNativeSystemTable(context, arg) {
      if (arg.mode === 'testMode') {
        // Random select programmable status
        const publishedUnitListTestModeTemp = JSON.parse(JSON.stringify(publishedUnitListTestMode));
        for (let index = 0; index < publishedUnitListTestModeTemp.length; ++index) {
          if (Math.random() < 0.5) {
            publishedUnitListTestModeTemp[index].reprogrammable_info.force.reprogrammable_status = config.SYSTEM_STATUS_NOT_REPROGRAMMABLE;
            publishedUnitListTestModeTemp[index].pfc_file_id = null;
          }
          // Special handling for RCR to display target unit information
          if (publishedUnitListTestModeTemp[index].system_names.length > 1 &&
            publishedUnitListTestModeTemp[index].system_names[0].system_name === 'RCR_L') {
            const targetRomId = publishedUnitListTestModeTemp[index].target_romid;
            publishedUnitListTestModeTemp[index].target_romid = `LH：${targetRomId.slice(0, -1)}0  RH：${targetRomId.slice(0, -1)}2`;
          }
        }
        const publishResult = resource.getVehicleSystemStatus(publishedUnitListTestModeTemp, this.suFlow);
        for (let index = 0; index < publishResult.vehicleSystemList.length; ++index) {
          publishResult.vehicleSystemList[index].name = publishedUnitListTestModeTemp[index].name;
          publishResult.vehicleSystemList[index].fieldSpec.isCommunicatable = config.LIST_ITEM_DISABLE;
          publishResult.vehicleSystemList[index].fieldSpec.isInstallation = config.LIST_ITEM_DISABLE;
        }
        // テーブルをキャッシュ
        const tbl = {
          published: util.sortItems(publishResult.vehicleSystemList, 'id', config.ASC),
          unpublished: []
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
      else if (arg.mode === 'techMode') {
        // テーブルをキャッシュ
        const tbl = {
          published: [],
          unpublished: []
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
      else {
        if (!arg.netAppId) {
          return { isValid: false, data: null };
        }
        const pblEndpoint = '/netApps/' + arg.netAppId + '/vehicle/units/published';
        const unpblEndpoint = '/netApps/' + arg.netAppId + '/vehicle/units/unpublished';

        let pblTbl = [];
        let unpblTbl = [];
        let errObject = null;

        // 公開テーブル取得
        const pblRes = await api.getCall(config.REPRO, pblEndpoint);
        const isValid = handler.validate(
          handler.validateTypes.all, pblRes, arg.obj, null, null, (result) => {
            errObject = result;
          }, null, false
        );
        if (!isValid || errObject) return { isValid: isValid, data: errObject };
        pblTbl = pblRes.data;

        // 非公開テーブル取得
        if (arg.isBothTable) {
          const unpblRes = await api.getCall(config.REPRO, unpblEndpoint);
          const isValid = handler.validate(
            handler.validateTypes.all, unpblRes, arg.obj, null, null, (result) => {
              errObject = result;
            }, null, false
          );
          if (!isValid || errObject) return { isValid: isValid, data: errObject };
          unpblTbl = unpblRes.data;
        }

        // テーブルをキャッシュ
        const tbl = {
          published: util.sortItems(pblTbl, 'id', config.ASC),
          unpublished: util.sortItems(unpblTbl, 'id', config.ASC)
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
    },

    // カスタマイズ一覧情報を取得
    getCustomizeSystemTable(context, arg) {
      context.commit('setCustomizeTable', arg);
    },

    setSystemTblModalFlag(context, arg) {
      context.commit('setSystemTblModalFlag', arg);
    },

    updateAllDtcDataFromIndividualDtcData(context, arg) {
      context.commit('setIndividualDtcData', arg);
    },

    // 情報を初期化
    clearTableData(context) {
      context.commit('setNativeSystemTable', null);
      context.commit('setSystemTblModalFlag', true);
      context.commit('setAllDtcTable', null);
      context.commit('setCustomizeTable', null);
    },
  }
};