<div class="container data-monitor-marker">
  <b-overlay :show="modalLoading" opacity="0.8" spinner-variant="primary" rounded="lg">
    <div class="col-12">
      <div class="row ml-1 mt-2">
        <div class="col-2">
          <label>{{ $t('individual.label_data_monitor_timestamp') }}</label>
        </div>
        <div class="col-10">
          <label>{{ time }}</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row ml-1 mt-2">
        <div class="col-2">
          <label>{{ $t('individual.label_data_monitor_comment') }}</label>
        </div>
        <div class="col-10">
          <b-form-textarea type="text" v-model="comment" size="sm" rows="3" :state="isAlert.comment.valid">
            {{ comment }}
          </b-form-textarea>
          <b-form-invalid-feedback>{{ $t(isAlert.comment.msg, [isAlert.comment.length]) }}</b-form-invalid-feedback>
        </div>
      </div>
    </div>
  </b-overlay>
</div>