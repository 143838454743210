<!-- FOTA ステータスチェック -->
<b-modal v-model="modal_requirement_091" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">

      <!-- 実行中画面 -->
      <template v-if="phase === 'start'">
        {{ $t(`individual.common_title_execution`) }}
      </template>

      <!-- 読出し結果表示画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- 共通エラー画面 -->
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>

    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 読出し結果表示画面 -->
        <template v-if="phase === 'success'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ displayInfoFotaStatus }}</div>
          </div>
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ displayInfoFotaDetail }}</div>
          </div>
        </template>

        <!-- 共通エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>

      </div>
    </b-overlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center">

      <!-- 読出し結果表示画面 -->
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'success' || phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>

</b-modal>