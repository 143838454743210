<div class="container-fluid p-0" style="height: 100%">
  <div class="row no-gutters info-background">
    <div v-bind:class="[{ 'col-3': isTreeViewOpen }, { 'width-40': !isTreeViewOpen }]">
      <div>
        <div v-show="isTreeViewOpen" class="tree-seperator float-left" style="width: 90%;">
          <div v-if="!isTechnicalHq && isDisplayReproTreeView && isReproVisible">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.header_vehicle_system_tree_repro') }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="reproTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickReproTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>
          <div v-if="isDisplayTreeView && isAllDtcVisible && hasAllDtcSystems">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.label_function_menu_all_dtc') }}</span>
            </div>
            <div>
              <system-tree ref="allDtcTreeView" v-bind:tree-data="allDtcTreeData" v-bind:showHeadElements="true"
                isAutoHeight @node-Selected="clickAllDtcTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>
          <div v-if="isDisplayTreeView && isIndividualVisible && hasIndividualMenus">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('individual.label_menu_individual_diagnosis') }}</span>
            </div>
            <div>
              <system-tree v-if="diagTreeData" ref="treeView" v-bind:tree-data="diagTreeData"
                v-bind:showHeadElements="true" isAutoHeight @node-Selected="clickDiagTreeNode"
                @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplayImbTreeView && isImbVisible">
            <div class="tree-header-div">
              <span class="tree-header-label float-left"> {{ $t('imb.common_immobilizer') }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="immobilizerTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickImmobilizerTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplayMacTreeView && isMackeyVisible">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('mac.label_mac_key') }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="macKeyTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickMacKeyTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplaySpecialFeature && isSpecialFeatureSupport">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.label_function_menu_special_feature') }}</span>
            </div>
            <div>
              <system-tree v-if="specialFeatureTreeData" ref="specialTreeView" v-bind:tree-data="specialFeatureTreeData"
                v-bind:showHeadElements="true" isAutoHeight @node-Selected="clickSpecialFeatureTreeNode"
                @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplayRealWorldDataTreeView && isDisplayRealWorldData">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.label_function_menu_vehicle_information')
                }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="realWorldDataTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickRealWorldDataTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

        </div>
        <div v-if="isTreeViewShow">
          <div class="div-arrow-radius" @click="switchTreeDisplay()">
            <svg v-if="isTreeViewOpen" viewBox="0 0 24 24" class="arrow-icon">
              <path d="M14 7l-5 5 5 5V7z" class="icon-color" />
              <path d="M24 0v24H0V0h24z" fill="none" />
            </svg>
            <svg v-else viewBox="0 0 24 24" class="arrow-icon">
              <path d="M10 17l5-5-5-5v10z" class="icon-color" />
              <path d="M0 24V0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-bind:class="[{ 'col-9': isTreeViewOpen }, { 'col': !isTreeViewOpen }]" style="width: 90%;">
      <div class="col-12 row" id="scrollTabBlock">
        <div class="col-auto text-left mt-3">
          <label class="system-name-style" v-if="isShowSelectedSystem">{{ selectedSystem }}</label>
        </div>
        <div class="col text-right mt-3">
          <svg class="icon-style" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512">
            <g>
              <path class="st0" d="M34.313,203.5c2.297-5.031,6.703-13.297,14.422-27.813c3.547-6.688,7.516-14.063,11.578-21.625H24.719
		C11.078,154.063,0,165.109,0,178.781C0,192.438,11.078,203.5,24.719,203.5H34.313z" />
              <path class="st0" d="M487.281,154.063h-35.594c4.078,7.563,8.031,14.938,11.578,21.625c7.719,14.516,12.109,22.781,14.422,27.813
		h9.594c13.641,0,24.719-11.063,24.719-24.719C512,165.109,500.922,154.063,487.281,154.063z" />
              <path class="st0" d="M39.391,417.781c0,18.406,14.938,33.328,33.328,33.328c18.406,0,33.313-14.922,33.313-33.328v-31.516H39.391
		V417.781z" />
              <path class="st0" d="M405.938,417.781c0,18.406,14.938,33.328,33.344,33.328s33.328-14.922,33.328-33.328v-31.516h-66.672V417.781z
		" />
              <path class="st0" d="M467.875,209.688c1.688,0.5-61.688-115.813-64.719-122.094c-8-16.656-27.781-26.703-47.063-26.703
		c-22.281,0-84.344,0-84.344,0s-93.563,0-115.859,0c-19.297,0-39.031,10.047-47.063,26.703
		c-3.031,6.281-66.375,122.594-64.703,122.094c0,0-20.5,20.453-22.063,22.094c-8.625,9.266-8,17.281-8,25.313c0,0,0,75.281,0,92.563
		c0,17.266,3.078,26.719,23.438,26.719h437c20.359,0,23.453-9.453,23.453-26.719c0-17.281,0-92.563,0-92.563
		c0-8.031,0.609-16.047-8.047-25.313C488.406,230.141,467.875,209.688,467.875,209.688z M96.563,174.016
		c0,0,40.703-73.313,43.109-78.109c4.109-8.188,15.828-14.125,27.813-14.125h177.031c12,0,23.703,5.938,27.813,14.125
		c2.422,4.797,43.125,78.109,43.125,78.109c3.75,6.75,0.438,19.313-10.672,19.313H107.219
		C96.109,193.328,92.813,180.766,96.563,174.016z M91.125,337.063c-20.656,0-37.406-16.734-37.406-37.391
		c0-20.672,16.75-37.406,37.406-37.406s37.391,16.734,37.391,37.406C128.516,320.328,111.781,337.063,91.125,337.063z
		M312.781,347.172c0,2.734-2.219,4.953-4.938,4.953H204.172c-2.734,0-4.953-2.219-4.953-4.953V301.5
		c0-2.703,2.219-4.906,4.953-4.906h103.672c2.719,0,4.938,2.203,4.938,4.906V347.172z M420.875,337.063
		c-20.656,0-37.422-16.734-37.422-37.391c0-20.672,16.766-37.406,37.422-37.406s37.406,16.75,37.406,37.406
		S441.531,337.063,420.875,337.063z" />
            </g>
          </svg>
          <label v-show="specMode === 'abroad'" class="mr-2 vehicle-info-style">{{
            $t('message.label_vehicle_system_vin') }} {{ vin }}</label>
          <label v-show="specMode === 'domestic'" class="mr-2 vehicle-info-style">{{
            $t('message.label_vehicle_system_cn') }} {{ vin }}</label>
          <label v-show="specMode === 'abroad'" class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_my')
            }} {{ spec }}</label>
          <label v-show="specMode === 'domestic'" class="mr-2 vehicle-info-style">{{
            $t('message.label_vehicle_system_cl') }} {{ spec }}</label>
          <label class="vehicle-info-style" :class="{ 'mr-2': specMode === 'domestic' }">{{
            $t('message.label_vehicle_system_model') }} {{ modelDisplayName }}</label>
          <label v-show="specMode === 'domestic'" class="vehicle-info-style">{{
            $t('message.label_vehicle_system_applied_model') }} {{ appliedModel }}</label>
        </div>
      </div>
      <router-view @updateReproTreeView="updateReproTreeView" @updateDiagTreeView="updateDiagTreeView"
        @updateMacTreeView="updateMacTreeView" @updateImmobilizerTreeView="updateImmobilizerTreeView"
        @activateInquiredNode="activateInquiredNode" @setIsSystemHeaderDisplay="setIsSystemHeaderDisplay"
        @updateSpecialFeatureTreeView="updateSpecialFeatureTreeView"
        @updateRealWorldDataTreeView="updateRealWorldDataTreeView" v-slot="{ Component }">
        <component ref="informationTabs" :is="Component" />
      </router-view>
    </div>
  </div>

  <!-- modal dialog  -->
  <div>
    <!-- 関連リプロ対象システムが存在している際に表示されるダイアログ -->
    <b-modal v-model="showModalRelation" id="modal-relation-repro" size="lg" header-bg-variant="info"
      header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc>
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.common_title_infomation') }}
        </div>
      </template>

      <template v-slot:default>
        <div class="text-left w-100 col-12 mt-3 pl-3" style="font-weight: bold;">
          <div v-html="relationReproProcessMsg"></div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="primary" @click="showModalRelation=false" class="ml-2 mr-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>