<!-- エアバッグECU, センサID表示 -->
<b-modal v-model="modal_special_requirement_ABG1" :size="dialogSize" :header-bg-variant="headerColor"
  header-text-variant="light" no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'result' || phase === 'no_supported'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- データ読出し -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12"></div>
          </div>
        </template>
        <!-- 正常終了(一覧表示) -->
        <template v-if="phase === 'result'">
          <div class="row no-gutters">
            <div class="col-12" v-show="displayInfo">
              <cstm-table printSetting="print-area" useStripeStyle useSort initSortDir="asc" hasTabs
                :items="displayInfo" currentSortkey="id" :columns="resultTableColumns" class="mt-2"
                type="simple">
              </cstm-table>
            </div>
          </div>
        </template>
        <!-- 正常終了(非サポート) -->
        <template v-if="phase === 'no_supported'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`individual.label_not_supported_ABG1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <b-button v-if="phase === 'result'" size="sm" variant="primary" @click="startPrint()" class="ml-2 mr-2">
        {{ $t('message.button_vehicle_dtc_print') }}
      </b-button>
      <b-button v-if="phase !== 'start'" size="sm" variant="primary" @click="closeWizard" class="mx-2">
        {{ phase !== 'result' ? $t('message.common_button_ok') : $t('message.button_polling_alert_terminate') }}
      </b-button>
    </div>
  </template>
</b-modal>