<b-modal v-model="modal_requirement_134" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 確認
      キャリブレーション対象カメラ選択, パラメータ更新 -->
      <template v-if="phase === 'cameraSelect' || phase === 'parameter'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中
      初期, マップ生成処理開始 -->
      <template v-if="phase === 'start' || phase === 'mapGenerate'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- エラー
      実行条件エラー, 開始処理エラー表示, 停止処理エラー表示, 共通エラー -->
      <template
        v-if="phase === 'conditionsError' || phase === 'startError' || phase === 'stopError' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
          </div>
        </template>
        <!-- キャリブレーション対象カメラ選択 -->
        <template v-if="phase === 'cameraSelect'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">
                  <b-form-select class="form-control" v-model="selectedCamera" :options="cameraList"
                    :select-size="4"></b-form-select>
                </div>
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_134_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- パラメータ更新 -->
        <template v-if="phase === 'parameter'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs rowSelect :items="itemsPhaseUpdate"
                  :columns="systemColumnsPhaseUpdate" type="simple" @rowClicked="onUpdateRowClicked">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- マップ生成処理開始 -->
        <template v-if="phase === 'mapGenerate'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_134_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 実行条件エラー -->
        <template v-if="phase === 'conditionsError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_134_TXT_3`) }}</div>
          </div>
        </template>
        <!-- 開始処理エラー表示 -->
        <template v-if="phase === 'startError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_134_TXT_4`) }}</div>
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(displayInfo) }}</div>
          </div>
        </template>
        <!-- 停止処理エラー表示 -->
        <template v-if="phase === 'stopError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_134_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- キャリブレーション対象カメラ選択 -->
      <template v-if="phase === 'cameraSelect'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" 
          @click="executeCalibrationStop" class="mx-2">
          {{ $t(`individual.label_button_finish`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || !selectedCamera" variant="primary"
          @click="executeCalibrationCameraSelect" class="mx-2">
          {{ $t(`individual.button_work_support_select`) }}
        </b-button>
      </template>
      <!-- パラメータ更新 -->
      <template v-if="phase === 'parameter'">
        <div class="d-flex justify-content-end">
          <b-button :disabled="isLoading || !mathButtonDisabled" size="sm" variant="primary"
            @click="onUpdateChangeValueClicked('subtract')" class="mx-2">
            {{ $t(`individual.label_minus`) }}
          </b-button>
          <b-button :disabled="isLoading || !mathButtonDisabled" size="sm" variant="primary"
            @click="onUpdateChangeValueClicked('add')" class="mx-2">
            {{ $t(`individual.label_plus`) }}
          </b-button>
        </div>
        <br />
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="executeCancelStart" class="mx-2">
          {{ $t(`${i18nWsKey}.button_cancel`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="executeMapGenerate" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 実行条件エラー -->
      <template v-if="phase === 'conditionsError'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="nextProcessTransition" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 開始処理エラー表示 -->
      <template v-if="phase === 'startError'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="nextProcessTransition" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止処理エラー表示 -->
      <template v-if="phase === 'stopError'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="nextProcessTransition" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>