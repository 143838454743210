<div>
  <!-- 注意アイコン -->
  <svg v-if="(iconKey == 'warningIcon')" viewBox="0 0 512 512" :class="iconStyle">
    <g>
      <path class="st0" d="M505.095,407.125L300.77,53.208c-9.206-15.944-26.361-25.849-44.774-25.849
  c-18.412,0-35.552,9.905-44.751,25.849L6.905,407.109c-9.206,15.944-9.206,35.746,0,51.69
  c9.206,15.944,26.354,25.842,44.758,25.842h408.674c18.405,0,35.568-9.897,44.759-25.842
  C514.302,442.855,514.302,423.053,505.095,407.125z M256.004,426.437c-17.668,0-32.013-14.33-32.013-32.004
  c0-17.668,14.345-31.997,32.013-31.997c17.667,0,31.997,14.329,31.997,31.997C288.001,412.108,273.671,426.437,256.004,426.437z
  M275.72,324.011c0,10.89-8.834,19.709-19.716,19.709c-10.898,0-19.717-8.818-19.717-19.709l-12.296-144.724
  c0-17.676,14.345-32.005,32.013-32.005c17.667,0,31.997,14.33,31.997,32.005L275.72,324.011z"
        style="fill: rgb(75, 75, 75);"></path>
    </g>
  </svg>

  <!-- チェックアイコン -->
  <svg v-if="(iconKey == 'checkIcon')" viewBox="0 0 512 512" :class="iconStyle">
    <g>
      <path class="st0" d="M469.402,35.492C334.09,110.664,197.114,324.5,197.114,324.5L73.509,184.176L0,254.336l178.732,222.172
l65.15-2.504C327.414,223.414,512,55.539,512,55.539L469.402,35.492z" style="fill: rgb(75, 75, 75);"></path>
    </g>
  </svg>

  <!-- エラーアイコン -->
  <svg v-if="(iconKey == 'errorIcon')" viewBox="0 0 512 512" :class="iconStyle">
    <g transform="translate(0,512) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M1000 4220 l0 -600 -69 0 c-64 0 -72 -2 -100 -31 l-31 -31 0 -750 c0
-516 3 -764 11 -792 17 -64 66 -159 101 -197 39 -41 103 -86 173 -118 83 -39
99 -44 183 -58 l32 -5 0 -178 0 -178 31 -31 31 -31 269 0 269 0 0 -117 c0
-113 -1 -119 -31 -169 -22 -35 -48 -61 -83 -83 l-52 -31 -617 0 -617 0 0 -200
0 -200 634 0 c348 0 646 4 662 9 16 5 49 15 74 22 169 50 349 230 399 399 7
25 17 58 22 74 5 16 9 89 9 163 l0 134 102 -3 102 -3 9 -35 c15 -57 47 -163
56 -185 34 -78 89 -184 102 -195 3 -3 20 -26 38 -52 103 -153 309 -309 496
-378 11 -4 31 -12 45 -18 14 -6 39 -13 55 -17 17 -3 53 -11 80 -18 68 -15 365
-15 435 1 30 7 70 16 88 19 18 3 35 9 38 14 3 5 12 9 20 9 41 0 242 111 333
184 51 41 160 156 192 203 18 26 35 50 38 53 32 28 111 208 139 315 6 22 16
60 22 85 16 59 16 351 0 410 -6 25 -16 63 -22 85 -32 123 -70 206 -149 325
-58 87 -177 217 -239 260 -19 13 -37 27 -40 30 -24 27 -193 113 -285 145 -114
40 -325 67 -424 54 -36 -5 -81 -8 -98 -9 l-33 0 0 529 0 529 -31 31 c-28 29
-36 31 -100 31 l-69 0 0 600 0 600 -1100 0 -1100 0 0 -600z m2000 -100 l0
-500 -900 0 -900 0 0 500 0 500 900 0 900 0 0 -500z m200 -1190 l0 -489 -67
-33 c-64 -30 -162 -86 -173 -98 -3 -3 -21 -17 -40 -30 -44 -31 -186 -175 -208
-212 -9 -15 -20 -28 -24 -28 -4 0 -8 -5 -8 -11 0 -6 -9 -24 -20 -39 -30 -42
-80 -140 -80 -156 0 -12 -92 -14 -592 -14 -612 0 -651 2 -773 43 -97 32 -195
113 -195 160 0 9 -4 19 -10 22 -7 4 -10 245 -10 691 l0 684 1100 0 1100 0 0
-490z m590 -624 c93 -20 110 -26 231 -85 48 -23 95 -50 105 -59 11 -9 36 -30
57 -46 49 -36 97 -84 133 -133 16 -21 32 -40 35 -43 14 -11 102 -175 113 -210
76 -255 76 -394 0 -650 -9 -30 -79 -164 -107 -205 -33 -48 -166 -182 -211
-212 -23 -15 -43 -30 -46 -33 -12 -12 -114 -65 -157 -81 -13 -5 -33 -13 -45
-18 -13 -5 -45 -14 -73 -20 -27 -5 -75 -15 -105 -22 -91 -18 -327 5 -415 41
-110 45 -191 85 -205 101 -3 3 -25 19 -50 36 -48 33 -173 158 -207 208 -40 59
-99 181 -120 250 -63 206 -60 362 13 605 11 35 99 199 113 210 3 3 19 22 35
43 36 49 84 97 133 133 21 16 46 36 56 45 16 15 155 86 212 110 27 10 81 26
130 38 64 14 302 13 375 -3z m-1287 -773 c-3 -49 -6 -94 -8 -100 -4 -10 -112
-13 -500 -13 l-495 0 0 100 0 100 503 0 503 0 -3 -87z" />
      <path d="M1400 4020 l0 -100 200 0 200 0 0 100 0 100 -200 0 -200 0 0 -100z" />
      <path d="M2400 4020 l0 -100 200 0 200 0 0 100 0 100 -200 0 -200 0 0 -100z" />
      <path d="M2024 3126 c-90 -33 -147 -99 -174 -199 -38 -144 95 -306 252 -307
222 -1 342 263 196 431 -67 76 -184 108 -274 75z m136 -121 c132 -68 90 -265
-57 -265 -57 0 -107 37 -132 98 -11 27 -12 40 -1 71 30 92 115 135 190 96z" />
      <path d="M3094 1904 l-66 -67 211 -211 c116 -116 211 -216 211 -221 0 -5 -96
-105 -212 -222 l-213 -213 65 -65 c35 -36 69 -65 75 -65 5 0 105 96 222 212
l213 213 213 -213 c117 -116 217 -212 222 -212 6 0 40 29 75 65 l65 65 -213
213 c-116 117 -212 217 -212 222 0 5 95 105 211 221 l212 212 -54 57 c-89 94
-64 105 -298 -129 -113 -113 -213 -206 -221 -206 -8 0 -107 92 -220 205 -113
113 -208 205 -212 205 -4 0 -37 -30 -74 -66z" />
    </g>
  </svg>

  <!-- イモビ登録アイコン -->
  <svg v-if="(iconKey == 'keyIcon')" viewBox="0 0 512 512" :class="iconStyle">
    <g>
      <path class="st0" d="M491.103,490.634l-12.05-86.739c-0.925-6.66-6.167-11.902-12.85-12.818l-33.622-4.655l-0.016-0.023
      l-4.646-33.598c-0.916-6.676-6.166-11.934-12.843-12.843l-22.182-3.064c-6.731-0.932-12.02-6.26-12.874-12.999l-7.193-56.494
      c-0.423-3.322-1.927-6.378-4.286-8.744l-35.769-35.762l-89.129,89.137l195.559,195.566c3.416,3.416,8.267,4.983,13.03,4.208
      l26.351-4.247C486.66,506.258,492.224,498.736,491.103,490.634z M445.367,467.456L279.6,301.696l12.826-12.826l165.768,165.76
      L445.367,467.456z" style="fill: rgb(75, 75, 75);"></path>
      <path class="st0" d="M369.159,133.852L241.801,6.493c-8.658-8.658-22.7-8.658-31.358,0L27.241,189.703
      c-8.65,8.65-8.65,22.691,0,31.342L154.6,348.412c8.666,8.658,22.699,8.658,31.358,0L369.159,165.21
      C377.818,156.552,377.818,142.51,369.159,133.852z M205.201,133.499l-50.954,50.954c-7.569,7.57-19.855,7.57-27.432-0.008
      c-7.577-7.576-7.57-19.862-0.008-27.432l50.962-50.954c7.569-7.57,19.847-7.577,27.432,0.008
      C212.771,113.636,212.771,125.931,205.201,133.499z" style="fill: rgb(75, 75, 75);"></path>
    </g>
  </svg>

  <!-- mac鍵登録アイコン -->
  <svg v-if="(iconKey == 'macKeyIcon')" viewBox="0 0 512 512" :class="iconStyle">
    <g>
      <path d="M502.325,307.303l-39.006-30.805c-6.215-4.908-9.665-12.429-9.668-20.348c0-0.084,0-0.168,0-0.252
    c-0.014-7.936,3.44-15.478,9.667-20.396l39.007-30.806c8.933-7.055,12.093-19.185,7.737-29.701l-17.134-41.366
    c-4.356-10.516-15.167-16.86-26.472-15.532l-49.366,5.8c-7.881,0.926-15.656-1.966-21.258-7.586
    c-0.059-0.06-0.118-0.119-0.177-0.178c-5.597-5.602-8.476-13.36-7.552-21.225l5.799-49.363
    c1.328-11.305-5.015-22.116-15.531-26.472L337.004,1.939c-10.516-4.356-22.646-1.196-29.701,7.736l-30.805,39.005
    c-4.908,6.215-12.43,9.665-20.349,9.668c-0.084,0-0.168,0-0.252,0c-7.935,0.014-15.477-3.44-20.395-9.667L204.697,9.675
    c-7.055-8.933-19.185-12.092-29.702-7.736L133.63,19.072c-10.516,4.356-16.86,15.167-15.532,26.473l5.799,49.366
    c0.926,7.881-1.964,15.656-7.585,21.257c-0.059,0.059-0.118,0.118-0.178,0.178c-5.602,5.598-13.36,8.477-21.226,7.552
    l-49.363-5.799c-11.305-1.328-22.116,5.015-26.472,15.531L1.939,174.996c-4.356,10.516-1.196,22.646,7.736,29.701l39.006,30.805
    c6.215,4.908,9.665,12.429,9.668,20.348c0,0.084,0,0.167,0,0.251c0.014,7.935-3.44,15.477-9.667,20.395L9.675,307.303
    c-8.933,7.055-12.092,19.185-7.736,29.701l17.134,41.365c4.356,10.516,15.168,16.86,26.472,15.532l49.366-5.799
    c7.882-0.926,15.656,1.965,21.258,7.586c0.059,0.059,0.118,0.119,0.178,0.178c5.597,5.603,8.476,13.36,7.552,21.226l-5.799,49.364
    c-1.328,11.305,5.015,22.116,15.532,26.472l41.366,17.134c10.516,4.356,22.646,1.196,29.701-7.736l30.804-39.005
    c4.908-6.215,12.43-9.665,20.348-9.669c0.084,0,0.168,0,0.251,0c7.936-0.014,15.478,3.44,20.396,9.667l30.806,39.007
    c7.055,8.933,19.185,12.093,29.701,7.736l41.366-17.134c10.516-4.356,16.86-15.168,15.532-26.472l-5.8-49.366
    c-0.926-7.881,1.965-15.656,7.586-21.257c0.059-0.059,0.119-0.119,0.178-0.178c5.602-5.597,13.36-8.476,21.225-7.552l49.364,5.799
    c11.305,1.328,22.117-5.015,26.472-15.531l17.134-41.365C514.418,326.488,511.258,314.358,502.325,307.303z M281.292,329.698
    c-39.68,16.436-85.172-2.407-101.607-42.087c-16.436-39.68,2.407-85.171,42.087-101.608c39.68-16.436,85.172,2.407,101.608,42.088
    C339.815,267.771,320.972,313.262,281.292,329.698z" style="fill: rgb(75, 75, 75);"></path>
    </g>
  </svg>

  <!-- 特殊機能アイコン -->
  <svg :class="iconStyle" v-if="(iconKey == 'specialFeatureIcon')" viewBox="0 0 24 24">
    <polygon points="14.43,10 12,2 9.57,10 2,10 8.18,14.41 5.83,22 12,17.31 18.18,22 15.83,14.41 22,10" style="fill: rgb(75, 75, 75);"/>
  </svg>

  <!-- システム個別診断アイコン -->
  <svg :class="iconStyle" v-if="(iconKey == 'individualIcon')" viewBox="0 0 20 20">
    <g>
      <path d="M4.5,4.5H6V7h8V4.5h1.5V9H17V4.5C17,3.67,16.33,3,15.5,3h-3.57c-0.22-0.86-1-1.5-1.93-1.5S8.29,2.14,8.07,
      3H4.5 C3.67,3,3,3.67,3,4.5v11C3,16.33,3.67,17,4.5,17H9v-1.5H4.5V4.5z M10,3c0.41,0,0.75,0.33,0.75,0.75c0,0.41-0.34,
      0.75-0.75,0.75 S9.25,4.16,9.25,3.75C9.25,3.33,9.59,3,10,3z" style="fill: rgb(75, 75, 75);"/>
      <path d="M16.94,15.88c0.35-0.54,0.56-1.19,0.56-1.88c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5 c0.69,
      0,1.34-0.21,1.88-0.56L17.94,19L19,17.94L16.94,15.88z M14,16c-1.1,0-2-0.9-2-2c0-0.31,0.07-0.61,0.2-0.87 C12.52,12.46,
      13.21,12,14,12c1.1,0,2,0.9,2,2S15.1,16,14,16z" style="fill: rgb(75, 75, 75);"/>
    </g>
  </svg>
  
  <!-- 個車情報取得アイコン -->
  <svg v-if="(iconKey == 'realWorldDataIcon')" viewBox="0 -960 960 960" :class="iconStyle">
    <g>
      <path d="M320-704 200-824l56-56 120 120-56 56Zm320 0-56-56 120-120 56 56-120 120Zm-200-56v-200h80v200h-80ZM160 0q-17
      0-28.5-11.5T120-40v-320l84-240q6-18 21.5-29t34.5-11h440q19 0 34.5 11t21.5 29l84 240v320q0 17-11.5 28.5T800 0h-40q-17
      0-28.5-11.5T720-40v-40H240v40q0 17-11.5 28.5T200 0h-40Zm72-440h496l-42-120H274l-42 120Zm68 240q25
      0 42.5-17.5T360-260q0-25-17.5-42.5T300-320q-25 0-42.5 17.5T240-260q0 25 17.5 42.5T300-200Zm360 0q25
      0 42.5-17.5T720-260q0-25-17.5-42.5T660-320q-25 0-42.5 17.5T600-260q0 25 17.5 42.5T660-200Zm-460 40h560v-200H200v200Zm0 0v-200 200Z"
      style="fill: rgb(75, 75, 75);"></path>
    </g>
  </svg>
</div>