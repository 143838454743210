<div>
  <b-navbar type="dark" variant="dark" class="navbar-expand-md">
    <!-- Header left -->
    <b-navbar-nav class="mr-auto">
      <img src="/app-logo.png" class="ssm5-image-style" draggable="false">
      <div class="ml-2 version-info">
        <div class="version">Ver: {{ displayVersion }}</div>
        <div class="copyright">{{ copyright }}</div>
      </div>
    </b-navbar-nav>

    <!-- Header center 仕様変更によりタイトルは非表示 -->
    <!-- <b-navbar-nav class='navbar-text navbar-center mt-3'>
      <span v-if="showTitle" class="app-header-title-style">{{$t(headerTitleID)}}</span>
    </b-navbar-nav> -->

    <!-- Header right -->
    <b-navbar-nav class="navbar-right nav-info">
      <div v-if="isVoltage" class="border volatage-style mr-2 danger" :class="'border-' + voltageStyle">
        <div class="text-center ml-1 mr-1 mt-1 label-break-word" :class="voltageStyle">
          {{ $t('message.label_vehicle_system_voltage') }}</div>
        <div class="text-center ml-1 mr-1" :class="voltageStyle">{{ voltage }}</div>
      </div>
      <div v-if="isCloudConnector" class="text-center cloudConnector-status-style">
        <div class="cloudConnector-label">{{ $t('message.label_cloud_connector') }}</div>
        <div class="rounded-pill d-flex align-items-center justify-content-center cloudConnector-id">
          <div class="ml-2 mr-2">{{ appConnectStatus }}</div>
        </div>
      </div>
      <div>
        <button @click="openTroubleshootingFlow" class="open-troubleshoot-btn-style">
          <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px"
            class="open-troubleshoot-icon-style"
            @mouseover="isHoverTroubleshoot = true" @mouseleave="isHoverTroubleshoot = false">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              :class="{ 'icon-color': !isHoverTroubleshoot, 'icon-active-color': isHoverTroubleshoot}"
              d="m480-80-10-120h-10q-142 0-241-99t-99-241q0-142 99-241t241-99q71 0 132.5 26.5t108 73q46.5 46.5 73 108T800-540q0 75-24.5 144t-67 128q-42.5 59-101 107T480-80Zm80-146q71-60 115.5-140.5T720-540q0-109-75.5-184.5T460-800q-109 0-184.5 75.5T200-540q0 109 75.5 184.5T460-280h100v54Zm-101-95q17 0 29-12t12-29q0-17-12-29t-29-12q-17 0-29 12t-12 29q0 17 12 29t29 12Zm-29-127h60q0-30 6-42t38-44q18-18 30-39t12-45q0-51-34.5-76.5T460-720q-44 0-74 24.5T344-636l56 22q5-17 19-33.5t41-16.5q27 0 40.5 15t13.5 33q0 17-10 30.5T480-558q-35 30-42.5 47.5T430-448Zm30-65Z" />
          </svg>
        </button>
      </div>
      <b-dropdown right :disabled="userIconDisabled">
        <template v-slot:button-content>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            :class="{ 'icon-user-style': !userIconDisabled, 'icon-user-disabled-style' : userIconDisabled }"
            @mouseover="isHover = true" @mouseleave="isHover = false">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              :class="{ 'icon-color': !isHover, 'icon-active-color': isHover, 'icon-disabled-color' : userIconDisabled }"
              d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
        </template>
        <div v-for="item in dropdownItems">
          <div v-if="item.type === 'divider'" class="dropdown-divider"></div>
          <b-dropdown-item v-else @click=didTapDropdownItem(item.type)>
            {{ $t(item.label) }}
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </b-navbar-nav>
  </b-navbar>

  <!-- modal dialog  -->
  <div>
    <!-- ユーザー情報ダイアログ -->
    <b-modal v-model="showModalUser" id="modal-user-info" size="lg" header-bg-variant="secondary"
      header-text-variant="light" no-stacking ok-only>
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.menu_label_user_Info') }}
        </div>
      </template>

      <template v-slot:default>
        <b-overlay :show="modalLoading" opacity="0.3" spinner-variant="primary" no-fade>
          <div class="container-fluid mt-2 mb-2">
            <div class="row no-gutters">
              <div class="col-3">
                <div class="box-header text-right align-middle">
                  <span class="box-margin-right">{{ $t('message.label_user_sign_in_id') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.id }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_email') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.email_address }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_family_name') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.family_name }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_last_name') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.first_name }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_company') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value text-truncate">
                  <span class="box-margin-left">{{ userInfo.company_name }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_department') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.department }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_last_login_time') }}</span>
                </div>
              </div>
              <div class="col-9">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.previous_login }}</span>
                </div>
              </div>
              <div class="col-12 box-margin-top"></div>
              <div class="col-3" v-if="!userInfo.is_external_auth">
                <div class="box-header text-right">
                  <span class="box-margin-right">{{ $t('message.label_user_password_expiry') }}</span>
                </div>
              </div>
              <div class="col-9" v-if="!userInfo.is_external_auth">
                <div class="box-value">
                  <span class="box-margin-left">{{ userInfo.password_expired_time }}</span>
                  <a class="ml-4" href="javascript:void(0)" v-on:click="didTapChangePassword()">
                    <u>{{ $t("message.link_button_change_password") }}</u>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="primary" @click="showModalUser=false">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- 言語選択ダイアログ -->
    <b-modal v-model="showModalLang" id="modal-language" header-bg-variant="secondary" header-text-variant="light"
      centered no-stacking ok-only @ok="didTapLanguage">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.menu_label_language') }}
        </div>
      </template>

      <template v-slot:default>
        <div>
          <b-form-group class="form-group">
            <label class="sub-title-style">{{ $t('message.label_display_language') }}</label>
            <b-form-select v-model="selectedLanguage" size="sm" class="custom-select custom-select-sm">
              <option v-for="language in languageList" :value="language.value">
                {{ language.label }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="primary" @click="didTapLanguage()">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- 利用規約ダイアログ -->
    <b-modal v-model="showModalConditions" id="modal-user-conditions" header-bg-variant="secondary"
      header-text-variant="light" no-stacking ok-only scrollable>

      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.menu_label_terms') }}
        </div>
      </template>

      <template v-slot:default>
        <b-overlay :show="modalLoading" opacity="0.3" spinner-variant="primary" no-fade>
          <div v-html="userConditions"></div>
        </b-overlay>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="primary" @click="showModalConditions=false">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>

  <!-- フローナビゲーション領域 -->
  <div v-if='isShowNavigation' class="navigation-height">
    <flowNavigation :navigationItems="breadCrumbs" :disabled="headerDisabled" />
  </div>

  <!-- 表示領域 -->
  <div class="common-main" :class="mainContainerStyle" ref="mainContainer">
    <router-view @updateClientHeight="handleResize()" @closeAppAllModal="closeAppAllModal()" />
  </div>

  <bv-dialog style="z-index: 9999;" />

  <processing-view :processing="processing"></processing-view>

  <!-- <footer class="fixed-bottom footer-style">
  </footer> -->

</div>