<b-modal v-model="modal_requirement_117" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- メンテナンスモード開始確認表示, メンテナンスモード終了確認表示, メンテナンスモード開始, 
        メンテナンスモード終了, メンテナンスモード開始表示, メンテナンスモード終了表示, 正規搭載確認画面 -->
      <template v-if="phase === 'maintenanceStartConfirmation' || phase === 'maintenanceEndConfirmation' ||
        phase === 'maintenanceModeStart' || phase === 'maintenanceModeEnd' || phase === 'mountedConfirmation' ||
        phase === 'startDisplay' || phase === 'endDisplay'  ">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- メンテナンスモード開始/終了監視, センサ値判定 -->
      <template v-if="phase === 'start' || phase === 'startSurveillance' || phase === 'endSurveillance' || phase === 'sensorValue'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- メンテナンスモード開始不可表示, 車輪速超過表示, メンテナンスモード終了不可表示,
        圧力センサ値異常表示, キャンセル表示,  共通エラー -->
      <template v-if="phase === 'startFailed' || phase === 'speedError' || 
        phase === 'endFailed' || phase === 'cancel' || phase === 'sensorAbnormality' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- メンテナンスモード開始確認表示 -->
        <template v-if="phase === 'maintenanceStartConfirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_1`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード終了確認表示 -->
        <template v-if="phase === 'maintenanceEndConfirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_2`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード開始 -->
        <template v-if="phase === 'maintenanceModeStart'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_3`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード終了 -->
        <template v-if="phase === 'maintenanceModeEnd'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_4`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード開始/終了監視 -->
        <template v-if="phase === 'startSurveillance' || phase === 'endSurveillance'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_7_0001`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード開始表示 -->
        <template v-if="phase === 'startDisplay'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_8_0002`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード終了表示 -->
        <template v-if="phase === 'endDisplay'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_9_0002`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード開始不可表示 -->
        <template v-if="phase === 'startFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_12`) }}</div>
          </div>
        </template>
        <!-- 車輪速超過表示 -->
        <template v-if="phase === 'speedError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_10`) }}</div>
          </div>
        </template>
        <!-- 正規搭載確認画面 -->
        <template v-if="phase === 'mountedConfirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_5`) }}</div>
          </div>
        </template>
        <!-- センサ値判定 -->
        <template v-if="phase === 'sensorValue'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_6`) }}</div>
          </div>
        </template>
        <!-- メンテナンスモード終了不可表示 -->
        <template v-if="phase === 'endFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_13`) }}</div>
          </div>
        </template>
        <!-- 圧力センサ値異常表示 -->
        <template v-if="phase === 'sensorAbnormality'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_11`) }}</div>
          </div>
        </template>
        <!-- キャンセル表示 -->
        <template v-if="phase === 'cancel'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_117_TXT_14`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- メンテナンスモード開始確認表示 -->
      <template v-if="phase === 'maintenanceStartConfirmation'">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="onCancelClicked"
          class="mx-2"
          :disabled="isLoading"
        >{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onMaintenanceStartConfirmationOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード終了確認表示 -->
      <template v-if="phase === 'maintenanceEndConfirmation'">
        <b-button size="sm" variant="primary" @click="onMaintenanceEndConfirmationOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード開始 -->
      <template v-if="phase === 'maintenanceModeStart'">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="onCancelClicked"
          class="mx-2"
          :disabled="isLoading"
        >{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onMaintenanceModeStartOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード終了 -->
      <template v-if="phase === 'maintenanceModeEnd'">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="onCancelClicked"
          class="mx-2"
          :disabled="isLoading"
        >{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onMaintenanceModeEndOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正規搭載確認画面 -->
      <template v-if="phase === 'mountedConfirmation'">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="checkCancel"
          class="mx-2"
          :disabled="isLoading"
        >{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onMountedConfirmationOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード開始表示 -->
      <template v-if="phase === 'startDisplay'">
        <b-button size="sm" variant="primary" @click="onMaintenanceModeStartFinishClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード終了表示 -->
      <template v-if="phase === 'endDisplay'">
        <b-button size="sm" variant="primary" @click="onMaintenanceModeEndFinishClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード開始不可表示 -->
      <template v-if="phase === 'startFailed'">
        <b-button size="sm" variant="primary" @click="onOtherErrorOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- メンテナンスモード終了不可表示 -->
      <template v-if="phase === 'endFailed'">
        <b-button size="sm" variant="primary" @click="onOtherErrorOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 圧力センサ値異常表示 -->
      <template v-if="phase === 'sensorAbnormality'">
        <b-button size="sm" variant="primary" @click="onOtherErrorOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー画面 -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>