<b-modal v-model="modal_requirement_069" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 調整方向指定画面, 調整値指定画面 -->
      <template v-if="(phase === 'inputDirection') || (phase === 'inputValue')">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 汎用異常終了画面 -->
      <template v-if="(phase === 'error') || (phase === 'failed')">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'"> </template>
        <!-- 調整方向指定画面 -->
        <template v-if="phase === 'inputDirection'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort rowSelect initSortDir="asc"
                  currentSortkey="itemName" :items="bodyItemsDirection" :columns="systemTableColumns" type="simple"
                  @rowClicked="selectItemDirection">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 調整値指定画面 -->
        <template v-if="phase === 'inputValue'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort rowSelect initSortDir="asc"
                  currentSortkey="itemName" :items="bodyItemsParam" :columns="paramTableColumns" type="simple"
                  @rowClicked="selectItemParam">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- エラー画面 -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_communication_error`) }}
            </div>
          </div>
        </template>
        <!-- 汎用異常終了画面 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 調整方向指定画面 -->
      <template v-if="phase === 'inputDirection'">
        <b-button size="sm" variant="outline-primary" @click="onDirectionExitClicked" class="mx-2"
          :disabled="isLoading">{{ $t(`message.button_polling_alert_terminate`) }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onDirectionOkClicked" class="mx-2" :disabled="isDisableButton">{{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 調整値指定画面 -->
      <template v-if="phase === 'inputValue'">
        <b-button size="sm" variant="outline-primary" @click="onValueCancelClicked" class="mx-2"
          :disabled="isLoading">{{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onValueAdjustmentClicked" class="mx-2"
          :disabled="isDisableButton">{{ $t(`individual.label_button_adjustment`) }}
        </b-button>
      </template>
      <!-- エラー画面 -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">{{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 汎用異常終了画面 -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">{{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>