<b-modal v-model="modal_requirement_058" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'execution'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template
        v-if="phase === 'conditionFailed' || phase === 'temperatureLimit' || phase === 'dtcDetection' || phase === 'abnormalFailed' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!--開始(確認)-->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_58_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'execution'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_58_TXT_2_0001`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_58_TXT_3_0002`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(実施条件未成立表示) -->
        <template v-if="phase === 'conditionFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_58_TXT_4`) }}</div>
          </div>
        </template>
        <!-- 異常終了(上限温度超過表示) -->
        <template v-if="phase === 'temperatureLimit'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_58_TXT_5_1700`) }}</div>
          </div>
        </template>
        <!-- 異常終了(DTC検出表示) -->
        <template v-if="phase === 'dtcDetection'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_58_TXT_6_1701`) }}</div>
          </div>
        </template>
        <!-- 異常終了(異常終了表示) -->
        <template v-if="phase === 'abnormalFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_58_TXT_7`) }}</div>
          </div>
        </template>
        <!-- 異常終了(共通エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 開始確認 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="executeProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了 -->
      <template v-if="phase !== 'start' && phase !== 'execution'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>