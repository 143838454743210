<!-- 設定保存ダイアログ -->
<b-modal v-model="modal_requirement_026" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'currentOilTemperature'||phase === 'lowerOilTemperature'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template
        v-if="phase === 'start'||phase === 'startEngine'||phase === 'pressBrake'||phase === 'selectRange'||phase === 'modeMove'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template
        v-if="phase === 'failed'||phase === 'vehicleCondition'||phase === 'oilCondition'||phase === 'executionCondition'||phase === 'higherOilTemperature'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- ローディング -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 車両状態不成立表示 -->
        <template v-if="phase === 'vehicleCondition'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_26_TXT_1`) }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 現在油温表示 -->
        <template v-if="phase === 'currentOilTemperature'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- エンジンON指示 -->
        <template v-if="phase === 'startEngine'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_26_TXT_3`) }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- ブレーキ踏み込み指示/ブレーキ踏み込み維持指示 -->
        <template v-if="phase === 'pressBrake'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- レンジセレクト指示 -->
        <template v-if="phase === 'selectRange'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- モード移行確認表示 -->
        <template v-if="phase === 'modeMove'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 油温条件不成立表示 -->
        <template v-if="phase === 'oilCondition'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行条件不成立表示 -->
        <template v-if="phase === 'executionCondition'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;"> {{
                  $t(`${i18nWsKey}.REQ_WS_26_TXT_15`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 作業可能油温割れ表示 -->
        <template v-if="phase === 'lowerOilTemperature'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 作業可能油温超過表示 -->
        <template v-if="phase === 'higherOilTemperature'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_26_TXT_16`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(共通エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
        <!-- 成功画面 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  displayInfo }} </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 車両状態不成立表示 -->
      <template v-if="phase === 'vehicleCondition'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="loadingProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 現在油温表示 -->
      <template v-if="phase === 'currentOilTemperature'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="startEnginProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- モード移行確認表示 -->
      <template v-if="phase === 'modeMove'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="iocpOutputKeepStopProcess(1)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="iocpOutputKeepStopProcess(0)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 油温条件不成立表示 -->
      <template v-if="phase === 'oilCondition'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止, 実行条件不成立, 作業可能油温超過表示 -->
      <template v-if="phase === 'success' || phase === 'executionCondition' || phase === 'higherOilTemperature'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="iocpOutputReturn" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>