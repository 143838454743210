import resource from '../../../share/util/resourceMap';
import util from '../../../share/util/utils';
import cstmTable from '../../../share/components/vue/PaginationTable';
import config from '../../../share/config';
import { mapState } from 'vuex';
const FFD_I18N_MAP = require('../../../data/ffdi18nKeyMap.json');

export default {
  name: 'bmdModal',
  data() {
    return {
      bmdGroupOptions: [],
      selectedBMDGroup: null,
      bmdDetailTableColumns: [],
      bmdTableItems: [],
      currentBMDContents: {}
    };
  },

  props: {
    bmdTableProp: { type: Array, default: [], required: false },
    systemProp: { type: Object, default: {}, required: false },
    // モーダルにシステムと説明を表示する為のProps。未設定の場合は非表示となる
    bmdSystem: { type: String, default: '', required: false },
    bmdDescription: { type: String, default: '', required: false },
    dataPlayback: { type: Boolean, default: false, required: false }
  },

  components: {
    'cstm-table-modal': cstmTable,
  },

  methods: {

    /**
     * BMD グループの選択オプションを生成
     */
    makeBMDGroupOptions() {
      if (!this.bmdTableProp || this.bmdTableProp.length < 1) return;
      const saveOrderLatest = this.$t('individual.status_vehicle_bmd_order_latest');
      const flt = [];
      const opts = [];
      for (const p of this.bmdTableProp) {
        if (!flt.some(e => e.bmd_save_order === p.bmd_save_order)) {
          // 重複チェック用の配列データ
          flt.push({ bmd_save_order: p.bmd_save_order });
          // プルダウンのデータ生成
          const optData = { key: 0, value: { bmd_save_order: p.bmd_save_order, label: null } };
          if (p.bmd_save_order === 0) {
            optData.key = p.bmd_save_order + 1;
            optData.value.label = saveOrderLatest;
          }
          else if (p.bmd_save_order >= 1) {
            optData.key = p.bmd_save_order + 1;
            optData.value.label = this.$t('message.status_vehicle_did_order_past', [p.bmd_save_order]);
          }
          else {
            // 上記条件に合致しない場合は「最新で表示」
            optData.value.label = saveOrderLatest;
          }
          opts.push(optData);
        }
      }
      // プルダウンの表示順のソート「最新⇒1回前⇒2回前…」
      const bmdResultItem = util.sortItems(opts, 'key', config.ASC);
      this.bmdGroupOptions = bmdResultItem;
      // プルダウン初期表示
      this.selectedBMDGroup = bmdResultItem[0].key;
    },

    /**
     * BMD 一覧の生成
     * @param {*} group 選択したBMD
     */
    updateBMDTable(group) {
      const findIndex = this.bmdGroupOptions.findIndex(e => e.key === group);
      if (findIndex === -1) return;

      const bmdGroup = this.bmdGroupOptions[findIndex];
      // bmd_save_order が一致しているレコードを抽出
      const flt = this.bmdTableProp.filter(p => p.bmd_save_order === bmdGroup.value.bmd_save_order);
      const instance = this;
      const pre = [
        { name: 'item', label: 'message.header_vehice_ffd_item', useResource: true },
        { name: 'unit', label: 'message.header_vehice_ffd_unit', useResource: true },
      ];
      const bmd = resource.getSaveOrderTable(flt, this.systemProp.systemID, FFD_I18N_MAP, instance, true);
      // 重複なしで column を作成
      const cols = Array.from(new Set(bmd.headers));
      const detected = cols.map(e => (e ? { name: e, label: resource.changeDidHeaders(this, e), useHtmlBind: true } : { name: '0', label: 'message.header_vehice_ffd_detected' }));
      this.bmdDetailTableColumns = pre.concat(detected);
      // テーブルの内容を作成
      this.currentBMDContents = bmd;
      this.bmdTableItems = bmd.items;
    },

    /**
     *  BMD ソート初期化
     */
    initializeBMDSort() {
      this.bmdTableItems = this.bmdTableItems.concat();
    },

    async csvExport() {
      // テストdataを生成する
      let header = "";
      let body = "";

      const nameData = this.bmdDetailTableColumns.map(item => item["name"]);
      const headerLabelList = this.bmdDetailTableColumns.map(key => key);
      const vinHeader = `"${this.$t('message.label_measured_data_search_vin')}",${this.vinForCsv}\n`;
      const bmdCodeLabel = this.$t('message.header_vehicle_dtc_table_code');
      const bmdCodeData = util.escapeForCsv(this.systemProp.bmc);
      const bmdCodeHeader = `"${bmdCodeLabel}",${bmdCodeData}\n`;

      header = headerLabelList.map(head => {
        let formattedHeader = '';
        if (typeof (head["name"]) === "number") {
          formattedHeader = head["label"];
        } else {
          formattedHeader = this.$t(head["label"]) ? this.$t(head["label"]) : head;
        }
        return `"${formattedHeader}"`;
      }).join(",") + "\n";
      header = vinHeader + bmdCodeHeader + header;

      body = this.bmdTableItems.map((d) => {
        return nameData.map((key) => {
          let retVal = d[key];
          switch (key) {
            case "item":
            case "unit":
              retVal = this.$t(d[key]);
              break;
            default:
              break;
          }
          return util.escapeForCsv(retVal);
        }).join(",");
      }).join("\n");
      header = header.replaceAll("\n,", "\n");

      const data = header + body;
      const bomFormatData = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
      const blob = new Blob([bomFormatData, data], { type: 'text/csv;charset=utf-8' });
      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = ('{0}.csv').format("BMD"); // 出力するファイルの名前
      link.style.display = 'none';
      link.click();
      link.remove();
    },

    /**
     * テストデータを生成して返す
     */
    getTestData() {
      return this.currentBMDContents;
    },
  },

  computed: {
    ...mapState({
      cachedDetails: state => state.cache.projectDetails,
      vehicleInfo: state => state.vehicle.vehicleInfo
    }),
    vinForCsv() {
      const vin = this.dataPlayback ? this.cachedDetails.information.vin : this.vehicleInfo.vehicle.vin.vin;
      return util.escapeForCsv(vin);
    }
  },

  /**
   * createdイベント
   */
  created() {
    // noop
  },

  /**
   * mountedイベント
   */
  mounted() {
    // BMD グループの選択オプションを生成
    this.makeBMDGroupOptions();
    this.updateBMDTable(this.selectedBMDGroup);
  },
};