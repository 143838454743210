<div>
  <b-modal v-model="modal_requirement_038" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking>
    <template v-slot:header>
      <div class="text-center w-100">
        <!-- 確認
      オドメータ読込み, 実行, 新メータ書込み, 旧メータ書込み,警告表示, 処理中止表示 -->
        <template v-if="phase === 'start' || phase === 'execution' || phase === 'writeNewMeter' ||
      phase === 'writeOldMeter' || phase === 'warning' || phase === 'displayStop' || phase === 'displayPinNumber' ||
      phase === 'inputPinNumber'">
          {{ $t('message.common_title_confirmation') }}
        </template>
        <!-- 実行中
      書込み中表示, モード変更, 保存データ書込み -->
        <template v-if="phase === 'displayWriting'">
          {{ $t('individual.common_title_execution') }}
        </template>
        <!-- 成功
      書込み完了表示 -->
        <template v-if="phase === 'writeSuccess'">
          {{ $t('message.common_title_success') }}
        </template>
        <!-- エラー -->
        <template v-if="phase === 'connectionFailed' || phase === 'errorPinNumber'">
          {{ $t('message.common_title_error') }}
        </template>
      </div>
    </template>
    <template v-slot:default>
      <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
        <div class="container-fluid" style="min-height: 140px;">
          <!-- オドメータ読込み -->
          <template v-if="phase === 'start'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_check_read_odometer`) }}</div>
            </div>
          </template>
          <!-- 実行 -->
          <template v-if="phase === 'execution'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(displayInfo) }}</div>
            </div>
          </template>
          <!-- 新メータ書込み -->
          <template v-if="phase === 'writeNewMeter'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_check_write_odometer`) }}</div>
            </div>
          </template>
          <!-- 旧メータ書込み -->
          <template v-if="phase === 'writeOldMeter'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_recovery_odometer`) }}</div>
            </div>
          </template>
          <!-- 書込み中表示 -->
          <template v-if="phase === 'displayWriting'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(displayInfo) }}</div>
            </div>
          </template>
          <!-- 書込み完了表示 -->
          <template v-if="phase === 'writeSuccess'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_complete_write_odometer`) }}</div>
            </div>
          </template>
          <!-- 警告表示 -->
          <template v-if="phase === 'warning'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_warn_write_combination_meter`) }}</div>
            </div>
          </template>
          <!-- 処理中止表示 -->
          <template v-if="phase === 'displayStop'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_check_delete_odometer`) }}</div>
            </div>
          </template>
          <!-- 暗証番号表示 -->
          <template v-if="phase === 'displayPinNumber'">
            <div class="w-100">
              <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_38_TXT_10`) }}</div>
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ fixedPinNumber }}
              </div>
            </div>
          </template>
          <!-- 暗証番号入力 -->
          <template v-if="phase === 'inputPinNumber'">
            <div class="w-100">
              <div class="row no-gutters">
                <div class="col-4 offset-4">
                  <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_38_TXT_11`) }}</div>
                  <div class="text-left align-middle">
                    <b-form-group>
                      <b-form-input v-model="pinNumber" :state="validatePinNumber && !validatePinNumber.invalid"
                        type="text" size="sm" autocomplete="off">
                      </b-form-input>
                      <b-form-invalid-feedback v-if="validatePinNumber">{{ validatePinNumber.msg
                        }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 暗証番号入力エラー -->
          <template v-if="phase === 'errorPinNumber'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.REQ_WS_38_TXT_17`) }}</div>
            </div>
          </template>
          <!-- 異常終了(エラー) -->
          <template v-if="phase === 'connectionFailed'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t('individual.error_communication_error') }}</div>
            </div>
          </template>
        </div>
      </b-overlay>
    </template>
    <template v-slot:footer>
      <div class="w-100 text-center ">
        <!-- オドメータ読込み -->
        <template v-if="phase === 'start'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="readApi" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 実行 -->
        <template v-if="phase === 'execution'">
          <b-button size="sm" :disabled="isLoading" :hidden="isHidden" variant="outline-primary" @click="oldMeter"
            class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" :hidden="isHidden" variant="primary" @click="writeNewMeter"
            class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" :hidden="!isHidden" variant="primary" @click="writeOldMeter"
            class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 新メータ書込み -->
        <template v-if="phase === 'writeNewMeter'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="writeOldMeter" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayWriting" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 旧メータ書込み -->
        <template v-if="phase === 'writeOldMeter'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="displayStop" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayWriting" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 書込み完了表示 -->
        <template v-if="phase === 'writeSuccess'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayWarning" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 警告表示 -->
        <template v-if="phase === 'warning'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitApi" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayPinNumber" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 処理中止表示 -->
        <template v-if="phase === 'displayStop'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="writeNewMeter" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitApi" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 暗証番号表示 -->
        <template v-if="phase === 'displayPinNumber'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="inputPinNumber" class="mx-2">
            {{ $t('individual.label_input') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading || fixedPinNumber === ''" variant="primary" @click="writeApi"
            class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 暗証番号入力 -->
        <template v-if="phase === 'inputPinNumber'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="displayPinNumber" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="pinNumber = ''" class="mx-2">
            {{ $t('message.common_button_clear') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="validateInputPinNumber" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 暗証番号入力エラー -->
        <template v-if="phase === 'errorPinNumber'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitApi" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayPinNumber" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'connectionFailed'">
          <b-button size="sm" variant="primary" @click="exitApi" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
      </div>
    </template>
  </b-modal>
  <!-- 実行用別モーダル -->
  <component v-if="currentWizard" @close-wizard="closeWizard" :i18nWsKey="i18nWsKey" :workSupportId="workSupportId"
    :is="currentWizard" @isReturn="requirementReturn">
  </component>
</div>