import config from "../config";
import handler from '../util/apiResultValidator';
import alert from '../alert';
import logWrapper from "../../../execute/src/share/logWrapper";
import store from '../../../execute/src/store';

const FFD_I18N_MAP = require('../../../execute/src/data/ffdi18nKeyMap.json');
const REGION_SWITCH_LIST = new Map([['Europe', '_EUR'], ['NorthAmerica', '_NA'], ['All', '']]);

/**
 * ログアウト処理のコールバック関数
 * @callback CallbackLogout
 * @param {object} self 呼び出し元の this を想定
 */
/**
 * セッションリセット処理のコールバック関数
 * @callback CallbackResetSession
 * @param {object} self 呼び出し元の this を想定
 */
/**
 * API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
 * @callback CallbackAPIResponseError
 * @param {object} result handler.validate で渡ってくるエラー情報
 */
/**
 * エラー発生のコールバック関数
 * @callback CallbackError
 * @param {string} errorType エラーの種類（下記のいずれか）
 *                           - config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE
 *                           - config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE
 *                           - config.INDIVIDUAL_API_CALL_POLLING_COUNT_MAX
 *                           - POST API レスポンスチェックのコールバックでエラー文字列が渡された場合は、その文字列
 *                           - GET  API レスポンスチェックのコールバックでエラー文字列が渡された場合は、その文字列
 */
/**
 * POST API 呼び出し処理のコールバック関数
 * @callback CallbackPostAPICall
 * @returns {object} POST API 呼び出しのレスポンス
 */
/**
 * GET API 呼び出し処理のコールバック関数
 * @callback CallbackGetAPICall
 * @param {object} postApiResponse POST API 呼び出しのレスポンス
 * @returns {object} GET API 呼び出しのレスポンス
 */
/**
 * API 呼び出し処理のコールバック関数
 * @callback CallbackAPICall
 * @returns {object} API 呼び出しのレスポンス
 */
/**
 *API のレスポンスチェック処理のコールバック関数
 * @callback CallbackAPIResponseCheck
 * @param {object} apiResponse API 呼び出しのレスポンス
 * @returns {string} エラー時は任意の文字列、正常時は空文字列を返す。<br>
 *                   返した文字列は、その後のエラーコールバックの引数に渡ってくる。
 */
/**
 * GET API のレスポンスチェック処理のコールバック関数
 * @callback CallbackGetAPIResponseCheck
 * @param {object} getApiResponse GET API 呼び出しのレスポンス
 * @returns {string} 以下のいずれかを返すこと
 *                   - config.INDIVIDUAL_POLLING_RESULT_IN_PROGRESS
 *                   - config.INDIVIDUAL_POLLING_RESULT_COMPLETED
 *                   - 異常時はそれ以外の文字列
 */

/**
 * 個別診断で現在選択中のシステムを返す
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @returns 選択中のシステム ID
 */
export function getCurrentSystemId(instance) {
  return instance.$route.params.systemId;
}

/**
 * 個別診断で翻訳に使用する接頭辞を返す
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {Array[object]} menuList 個別診断メニューのリスト
 * @param {String} specifiedSystemId 任意のECUの翻訳キーを取得したい場合のみ指定
 * @returns ECU毎に異なる翻訳キー
 */
export function getFFDi18nKey(instance, menuList, specifiedSystemId = null) {
  const systemId = specifiedSystemId || getCurrentSystemId(instance);

  // Regionが指定されている場合、システム名にサフィックスを連結する
  const systemInfo = menuList.find(menu => menu.system_name === systemId);
  const region = systemInfo ? systemInfo.region : null;
  const regionSuffix = REGION_SWITCH_LIST.get(region);
  const idWithRegion = regionSuffix ? systemId.concat(regionSuffix) : systemId;

  // i18nResourcesを検索する
  const index = FFD_I18N_MAP.maps.findIndex(key => idWithRegion === key.systemId);
  // 検索結果があれば、返却値に検索結果を設定。無い場合は画面表示で判断させるためシステムIDをそのまま表示
  return (index !== -1) ? FFD_I18N_MAP.maps[index].i18nKey : idWithRegion;
}

/**
 * 画面名が個別診断画面であるか否かを返す
 * @param {string} name 画面名
 * @returns 個別診断画面の場合は true を返す
 */
export const isRouteNameIndividualDiagnose = (name) => {
  switch (name) {
    case config.INDIVIDUAL_FUNCTION_DIAG_CODE:
    case config.INDIVIDUAL_FUNCTION_CANCEL_CODE:
    case config.INDIVIDUAL_FUNCTION_DATA_MONITOR:
    case config.INDIVIDUAL_FUNCTION_VEHICLE_CONTROL_HISTORY:
    case config.INDIVIDUAL_FUNCTION_ACTIVE_TEST:
    case config.INDIVIDUAL_FUNCTION_WORK_SUPPORT:
    case config.INDIVIDUAL_FUNCTION_CUSTOMIZE:
      return true;
    default:
      return false;
  }
};

/**
 * 今いる画面が個別診断画面であるか否かを返す
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @returns 個別診断画面にいる場合に true を返す
 */
export const isCurrentRouteIndividualDiagnose = (instance) => {
  return isRouteNameIndividualDiagnose(instance.$router.currentRoute.value.name);
};

/**
 * 指定された個別診断画面に遷移する
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {string} individualFunction 遷移先の個別診断機能
 * @param {string} systemId 表示するシステム
 * @param {string} sameSystemFlg 同システム内の遷移判定（true: 同システム遷移, false: 別システム遷移）
 */
export function moveIndividualDiagnoseScreen(instance, individualFunction, systemId, sameSystemFlg = false) {
  switch (individualFunction) {
    case config.INDIVIDUAL_FUNCTION_DIAG_CODE:
    case config.INDIVIDUAL_FUNCTION_WORK_SUPPORT:
    case config.INDIVIDUAL_FUNCTION_VEHICLE_CONTROL_HISTORY:
    case config.INDIVIDUAL_FUNCTION_CUSTOMIZE:
    case config.INDIVIDUAL_FUNCTION_CANCEL_CODE:
    case config.INDIVIDUAL_FUNCTION_DATA_MONITOR:
    case config.INDIVIDUAL_FUNCTION_ACTIVE_TEST:
      instance.$router.push({
        name: individualFunction,
        params: {
          systemId: systemId
        },
        query: {
          sameSystemFlg: sameSystemFlg
        }
      });
      break;
    default:
    // noop
  }
}

/**
 * エラーハンドリングのデフォルト処理
 * - ※パラメータの詳細は、jsdoc を参照すること（individualDiagnose.js の上部にある各型の説明で Callback の詳細も記載している）
 * - ※タイミングによっては警告ダイアログが表示されない問題（特に wsp）を回避するため、async としている
 * @param {object} self 呼び出し元の this
 * @param {object} result handler.validate で渡ってくるエラー情報
 * @param {CallbackLogout} logoutCallback ログアウト処理のコールバック関数
 * @param {CallbackResetSession} resetSessionCallback セッションリセット処理のコールバック関数
 * @param {CallbackAPIResponseError} otherErrorCallback 共通処理が実施されなかった際のコールバック関数
 * @deprecated 以下の理由から非推奨とし、将来削除予定とする。今後は execute\src\share\util\api\errorAction.js を利用すること。\
 *             ・ファイル名が individual となっており、個別診断以外から利用しにくい。\
 *             ・share にいるにも関わらず、ファイル頭で execute の import をしており、manage から使えない。\
 *             ・eslint の500行制限に近づいているため、整理が必要。
 */
export async function apiErrorDefaultAction(
  self,
  result,
  logoutCallback = (self) => { self; },
  resetSessionCallback = (self) => { self; },
  otherErrorCallback = (result) => { result; },
) {
  const cause = result.causeType;
  const behaviorType = result.behaviorType;
  if (cause === handler.causeTypes.networkError ||
    cause === handler.causeTypes.invalidSessionToken ||
    cause === handler.causeTypes.clientConnectorNotAliveError
  ) {
    // セッションが不正・ネットワークエラー・CC 切断の場合、警告ダイアログを表示する
    alert.showWarning(self, null, result.message, () => {
      // 条件次第でコールバック関数を呼ぶ
      if (behaviorType === handler.behaviorTypes.logout) {
        logoutCallback(self);
      } else if (behaviorType === handler.behaviorTypes.resetSession) {
        resetSessionCallback(self);
      }
    });
  } else if (cause === handler.causeTypes.permissionError) {
    // ユーザー権限エラーの場合、エラーダイアログを表示しログアウトする
    alert.showError(self, null, result.message, () => {
      logoutCallback(self);
    });
  } else {
    // それ以外の場合は、エラー発生のコールバック関数を呼ぶ
    otherErrorCallback(result);
  }
}

/**
 * ポーリング処理を行う。
 * - ※パラメータの詳細は、jsdoc を参照すること（individualDiagnose.js の上部にある各型の説明で Callback の詳細も記載している）
 * @param {object} self this を指定
 * @param {object} postApiResponse POST API のレスポンス
 * @param {CallbackGetAPICall} getApiCallback GET API 呼び出し処理のコールバック関数
 * @param {CallbackError} errorCallback エラー発生のコールバック関数
 * @param {CallbackGetAPIResponseCheck} checkGetApiResponseCallback GET API のレスポンスチェック処理のコールバック関数
 * @param {CallbackAPIResponseError} checkGetApiErrorResultCallback GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {number} pollingRetryCount Option: ポーリングのリトライ回数指定（指定なしの場合は、通常通り環境変数の値を使用）
 * @param {number} pollingRetryInterval Option: ポーリングのリトライ待機時間（指定なしの場合は、通常通り環境変数の値を使用）
 * @returns {object} 最終的な GET レスポンス（エラー発生時は null になる）
 */
async function execPolling(
  self,
  postApiResponse,
  getApiCallback,
  errorCallback,
  checkGetApiResponseCallback,
  checkGetApiErrorResultCallback,
  pollingRetryCount = null,
  pollingRetryInterval = null,
) {
  return new Promise((resolve) => {

    // ポーリング時のリトライ回数（指定回数ありの場合は、指定回数を設定）
    let retryMaxCount = process.env.VUE_APP_RETRY_COUNT;
    if (!isNaN(pollingRetryCount) && pollingRetryCount > 0) {
      retryMaxCount = pollingRetryCount;
    }
    // ポーリング時のリトライ待機時間（指定回数ありの場合は、指定回数を設定）
    let retryInterval = process.env.VUE_APP_RETRY_INTERVAL;
    if (!isNaN(pollingRetryInterval) && pollingRetryInterval > 0) {
      retryInterval = pollingRetryInterval;
    }

    let currentRetry = 0;

    let getApiResponse = {};
    let errorType;

    try {
      const watcher = self.$startInterval(async () => {
        // 一定時間ごとに処理を実行し、ポーリング終了時にタイマー停止と resolve コールを行う

        let isPollingFinished = false;

        try {
          // GET API コールバックを呼ぶ
          getApiResponse = await getApiCallback(postApiResponse);
          handler.validate(handler.validateTypes.all, getApiResponse, self, null, () => {
            // API レスポンスが正常だった場合

            // レスポンスチェックのコールバックを呼ぶ
            const checkGetResponseResult = checkGetApiResponseCallback(getApiResponse);
            switch (checkGetResponseResult) {
              case config.INDIVIDUAL_POLLING_RESULT_CONTINUE:
                // ポーリング続行が返された場合
                if (++currentRetry >= retryMaxCount) {
                  // リトライ最大回数超過の場合のみ、ポーリング終了
                  isPollingFinished = true;
                  errorType = config.INDIVIDUAL_API_CALL_POLLING_COUNT_MAX;
                }
                break;
              case config.INDIVIDUAL_POLLING_RESULT_FINISH:
                // ポーリング終了が返された場合
                isPollingFinished = true;
                break;
              case config.INDIVIDUAL_POLLING_RESULT_ERROR:
              default:
                // それ以外は返ってきた文字列をそのままエラー文字列として格納
                isPollingFinished = true;
                errorType = checkGetResponseResult;
                break;
            }

            if (isPollingFinished) {
              // タイマー停止を呼ぶ
              self.$stopInterval(watcher);
            }

            if (errorType) {
              // エラー発生のコールバックを呼ぶ
              errorCallback(errorType);
            }
          }, (result) => {
            // API レスポンスが異常だった場合
            errorType = config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE;
            isPollingFinished = true;

            // タイマー停止を呼ぶ
            self.$stopInterval(watcher);

            // GET レスポンス異常のコールバックを呼ぶ
            checkGetApiErrorResultCallback(result);
          }, null, false);
        } catch (error) {
          // コールバック先等で exception が発生した場合のケア
          // ログ出力した上で、エラー処理を実施する
          logWrapper.log('error : ', error);

          errorType = config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE;
          isPollingFinished = true;

          // タイマー停止を呼ぶ
          self.$stopInterval(watcher);

          // エラー発生のコールバックを呼ぶ
          errorCallback && typeof ('function') && errorCallback(errorType);
        }

        if (isPollingFinished) {
          // ポーリング終了時は非同期処理を完了する
          // エラー発生時は null とする
          resolve(errorType ? null : getApiResponse);
        }
      }, retryInterval, "", true);
    } catch (error) {
      // コールバック先等で exception が発生した場合のケア
      // ログ出力した上で、エラー処理を実施する
      logWrapper.log('error : ', error);

      // エラー発生のコールバックを呼ぶ
      errorCallback && typeof ('function') && errorCallback(errorType);

      resolve(null);
    }
  });
}

/**
 * 個別診断のポーリング処理共通関数
 * - ※パラメータの詳細は、jsdoc を参照すること（individualDiagnose.js の上部にある各型の説明で Callback の詳細も記載している）
 * @param {object} self 呼び出し元の this を指定する
 * @param {CallbackPostAPICall} postApiCallback POST API 呼び出し処理のコールバック関数
 * @param {CallbackGetAPICall} getApiCallback GET API 呼び出し処理のコールバック関数
 * @param {CallbackError} errorCallback エラー発生のコールバック関数
 * @param {CallbackAPIResponseCheck} checkPostApiResponseCallback POST API のレスポンスチェック処理のコールバック関数
 * @param {CallbackGetAPIResponseCheck} checkGetApiResponseCallback GET API のレスポンスチェック処理のコールバック関数
 * @param {CallbackAPIResponseError} checkPostApiErrorResultCallback POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {CallbackAPIResponseError} checkGetApiErrorResultCallback GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {number} pollingRetryCount Option: ポーリングのリトライ回数指定（指定なしの場合は、通常通り環境変数の値を使用）
 * @param {number} pollingRetryInterval Option: ポーリングのリトライ待機時間指定（指定なしの場合は、通常通り環境変数の値を使用）
 * @param {boolean} pollingStopTimeOutFlg Option: ポーリング時のタイムアウト停止フラグ（指定なしの場合は、タイムアウト停止しない）
 * @returns {object} 最終的な GET レスポンス（エラー発生時は null になる）
 * @deprecated 以下の理由から非推奨とし、将来削除予定とする。今後は share\src\util\api\polling.js を利用すること。\
 *             ・ファイル名が individual となっており、個別診断以外から利用しにくい。\
 *             ・share にいるにも関わらず、ファイル頭で execute の import をしており、manage から使えない。\
 *             ・eslint の500行制限に近づいているため、整理が必要。
 */
export async function pollingApi(
  self,
  postApiCallback,
  getApiCallback,
  errorCallback = (errorType) => { errorType; },
  checkPostApiResponseCallback = (postApiResponse) => { postApiResponse; return ''; },
  checkGetApiResponseCallback = (getApiResponse) => { getApiResponse; return config.INDIVIDUAL_POLLING_RESULT_FINISH; },
  checkPostApiErrorResultCallback = (result) => { result; errorCallback(config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE); },
  checkGetApiErrorResultCallback = (result) => { result; errorCallback(config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE); },
  pollingRetryCount = null,
  pollingRetryInterval = null,
  pollingStopTimeOutFlg = false,
) {
  let errorType;
  let postApiResponse;

  try {
    // POST API コールバックを呼ぶ
    postApiResponse = await postApiCallback();
    handler.validate(handler.validateTypes.all, postApiResponse, self, null, () => {
      // API レスポンスが正常だった場合

      // レスポンスチェックのコールバックを呼ぶ
      errorType = checkPostApiResponseCallback(postApiResponse);
      if (errorType) {
        // レスポンスチェックでエラー文字列が返ってきた場合は、その文字列でコールバックを呼ぶ
        errorCallback(errorType);
      }
    }, (result) => {
      // API レスポンスが異常だった場合
      errorType = config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE;

      // POST レスポンス異常のコールバックを呼ぶ
      checkPostApiErrorResultCallback(result);
    }, null, false);
  } catch (error) {
    // コールバック先等で exception が発生した場合のケア
    // ログ出力した上で、エラー処理を実施する
    logWrapper.log('error : ', error);

    errorType = config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE;

    // エラー発生のコールバックを呼ぶ
    errorCallback && typeof ('function') && errorCallback(errorType);
  }

  if (errorType) {
    // エラー発生時は終了する
    return null;
  }

  try {
    if (pollingStopTimeOutFlg) {
      // ポーリング時のタイムアウト停止フラグ:true の場合、ポーリング実行中にタイムアウトを発生させない
      store.dispatch('app/setIsTimeoutLocked', true);
      store.dispatch('auth/stopSessionTimer');
    }
    // ポーリング処理
    return await execPolling(
      self,
      postApiResponse,
      getApiCallback,
      errorCallback,
      checkGetApiResponseCallback,
      checkGetApiErrorResultCallback,
      pollingRetryCount,
      pollingRetryInterval,
    );
  } finally {
    if (pollingStopTimeOutFlg) {
      // タイムアウト停止の終了
      store.dispatch('auth/resetSessionTimer');
      store.dispatch('app/setIsTimeoutLocked', false);
    }
  }
}

/**
 * 個別診断の同期 API 呼び出しの共通関数
 * - ※パラメータの詳細は、jsdoc を参照すること（individualDiagnose.js の上部にある各型の説明で Callback の詳細も記載している）
 * @param {object} self 呼び出し元の this を指定する
 * @param {CallbackAPICall} apiCallback API 呼び出し処理のコールバック関数
 * @param {CallbackError} errorCallback エラー発生のコールバック関数
 * @param {CallbackAPIResponseCheck} checkApiResponseCallback API のレスポンスチェック処理のコールバック関数
 * @param {CallbackAPIResponseError} checkApiErrorResultCallback API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @returns {object} 最終的な レスポンス（エラー発生時は null になる）
 * @deprecated 以下の理由から非推奨とし、将来削除予定とする。今後は share\src\util\api\syncRequest.js を利用すること。\
 *             ・ファイル名が individual となっており、個別診断以外から利用しにくい。\
 *             ・share にいるにも関わらず、ファイル頭で execute の import をしており、manage から使えない。\
 *             ・eslint の500行制限に近づいているため、整理が必要。
 */
export async function syncApi(
  self,
  apiCallback,
  errorCallback = (errorType) => { errorType; },
  checkApiResponseCallback = (apiResponse) => { apiResponse; return ''; },
  checkApiErrorResultCallback = (result) => { result; errorCallback(config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE); },
) {
  let errorType;
  let apiResponse;

  try {
    // API コールバックを呼ぶ
    apiResponse = await apiCallback();
    handler.validate(handler.validateTypes.all, apiResponse, self, null, () => {
      // API レスポンスが正常だった場合

      // レスポンスチェックのコールバックを呼ぶ
      errorType = checkApiResponseCallback(apiResponse);
      if (errorType) {
        // レスポンスチェックでエラー文字列が返ってきた場合は、その文字列でコールバックを呼ぶ
        errorCallback(errorType);
      }
    }, (result) => {
      // API レスポンスが異常だった場合
      errorType = config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE;

      // エラー発生のコールバックを呼ぶ
      checkApiErrorResultCallback(result);
    }, null, false);
  } catch (error) {
    // コールバック先等で exception が発生した場合のケア
    // ログ出力した上で、エラー処理を実施する
    logWrapper.log('error : ', error);

    errorType = config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE;

    // エラー発生のコールバックを呼ぶ
    errorCallback && typeof ('function') && errorCallback(errorType);
  }

  // エラー発生時は null を返す
  return errorType ? null : apiResponse;
}

export default {
  getCurrentSystemId,
  isRouteNameIndividualDiagnose,
  isCurrentRouteIndividualDiagnose,
  moveIndividualDiagnoseScreen,
  apiErrorDefaultAction,
  pollingApi,
  syncApi,
  getFFDi18nKey,
};
