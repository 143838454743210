import config from "../config";

/**
 * 指定された特殊機能画面に遷移する
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {string} systemId 表示するシステム
 * @param {string} specialFeatureFunction 遷移先の特殊機能
 */
export function moveSpecialFeatureScreen(instance, systemId, specialFeatureFunction) {
  switch (specialFeatureFunction) {
    case config.SPECIAL_FEATURE_WORK_SUPPORT:
    case config.SPECIAL_FEATURE_DIAG_CODE:
    case config.SPECIAL_FEATURE_CANCEL_CODE:
    case config.SPECIAL_FEATURE_DATA_MONITOR:
      instance.$router.push({
        name: specialFeatureFunction,
        params: {
          systemId: systemId
        }
      });
      break;
    default:
    // noop
  }
}

/**
 * 画面名が特殊機能の個別診断画面であるか否かを返す
 * @param {string} name 画面名
 * @returns 特殊機能の個別診断画面の場合は true を返す
 */
export const isRouteNameSpecialFeatureIndividualDiagnose = (name) => {
  switch (name) {
    case config.SPECIAL_FEATURE_DIAG_CODE:
    case config.SPECIAL_FEATURE_DATA_MONITOR:
    case config.SPECIAL_FEATURE_CANCEL_CODE:
    case config.SPECIAL_FEATURE_WORK_SUPPORT:
      return true;
    default:
      return false;
  }
};

/**
 * 今いる画面が特殊機能の個別診断画面であるか否かを返す
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @returns 特殊機能の個別診断画面にいる場合に true を返す
 */
export const isCurrentRouteSpecialFeatureIndividualDiagnose = (instance) => {
  return isRouteNameSpecialFeatureIndividualDiagnose(instance.$router.currentRoute.value.name);
};

export default {
  moveSpecialFeatureScreen,
  isRouteNameSpecialFeatureIndividualDiagnose,
  isCurrentRouteSpecialFeatureIndividualDiagnose
};
