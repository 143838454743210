<!-- 設定保存ダイアログ -->
<b-modal v-model="modal_requirement_025" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'start' || phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' || phase === 'writing'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'egOff' || phase === 'switchToFF' || phase === 'returnToAWD'
              || phase === 'switchToAWD' || phase === 'pRangeSelect'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'successSwitchToAWD' || phase === 'successSwitchToFF'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'failure'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start' || phase === 'writing'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(executionMsg) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- エンジン停止指示表示 -->
        <template v-if="phase === 'egOff'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- FF切り替え確認表示 -->
        <template v-if="phase === 'switchToFF'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- AWD戻し確認表示 -->
        <template v-if="phase === 'returnToAWD'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_3`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- AWD切り替え確認表示 -->
        <template v-if="phase === 'switchToAWD'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_4`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- Pレンジ指示表示 -->
        <template v-if="phase === 'pRangeSelect'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_5`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- AWD切り替え成功表示 -->
        <template v-if="phase === 'successSwitchToAWD'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_7`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- FF切り替え成功表示 -->
        <template v-if="phase === 'successSwitchToFF'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_25_TXT_8`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 失敗, エンジン停止指示表示（2回目） -->
        <template v-if="phase === 'failure'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(errorMsg) }}
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.error_communication_error') }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'egOff'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="getEngineStatus" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'switchToFF'">
        <b-button size="sm" variant="outline-primary" @click="exitLogic(0)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="showReturnAWDPage" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'returnToAWD' || phase === 'switchToAWD' || phase === 'pRangeSelect'">
        <b-button size="sm" variant="outline-primary" @click="exitLogic(0)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="getShiftStatus" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'successSwitchToAWD' || phase === 'successSwitchToFF' || phase === 'failure'">
        <b-button size="sm" variant="primary" @click="exitLogic(0)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="exitLogic(1)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>