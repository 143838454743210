<b-modal v-model="modal_requirement_049" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100" v-text="headerValue"></div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 合格表示, 不合格表示 -->
        <template v-if="phase === 'successSensitivity' || phase === 'conditionFailed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" v-html="displayInfo"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 乗員検知システム故障確認, 検査データ読出し, エラーコード表示 -->
        <template v-else-if="phase === 'crewDetection' || phase === 'readout' || phase === 'failedPoint'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 錘質量入力 -->
        <template v-else-if="phase === 'input'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message mb-3">{{ $t(displayInfo) }}</div>
                <b-form-input class="no-spin" v-model="inputWeight" :formatter="inputValueLimit" style="text-transform: uppercase" 
                  @keydown="portKeydown($event)" type="number" size="sm" autocomplete="off" />
              </div>
            </div>
          </div>
        </template>
        <!-- 他 -->
        <template v-else>
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(displayInfo) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 開始確認, キャリブレーション不良表示, センサ0点調整注意事項表示1~3 -->
      <template
        v-if="phase === 'start' || phase === 'calibration' || phase === 'pointPre1' || phase === 'pointPre2' || phase === 'pointPre3'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="changeForm" class="mx-2 w-25">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 車両状態確認 -->
      <template v-if="phase === 'conditionCheck'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="dtcReadProcess(false)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- センサ0点調整注意事項表示4 -->
      <template v-if="phase === 'pointPre4'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="dtcReadProcess(true)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- センサ0点調整成功表示, センサ感度検査注意事項表示1~7 -->
      <template
        v-if="phase === 'successPoint' || phase === 'sensitivityPre1' || phase === 'sensitivityPre2' || phase === 'sensitivityPre3'
                      || phase === 'sensitivityPre4' || phase === 'sensitivityPre5' || phase === 'sensitivityPre6' || phase === 'sensitivityPre7'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="changeForm" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 錘質量入力指示表示 -->
      <template v-if="phase === 'inputInst'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="changeForm" class="mx-2">
          {{ $t(`individual.label_input`) }}
        </b-button>
      </template>
      <!-- 錘質量入力 -->
      <template v-if="phase === 'input'">
        <div class="text-right w-100">
          <button type="button" @click="clearText" class="btn clear-button ml-3 mb-3 btn-outline-primary btn-sm rounded-pill">
            {{ $t("message.common_button_clear") }}
          </button>
        </div>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="cancelProcess" class="ml-2 mr-2">
            {{ $t("message.common_button_cancel") }}
          </b-button>
          <b-button size="sm" variant="primary" :disabled="isLoading" @click="changeForm" class="ml-2 mr-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <!-- 検査準備表示 -->
      <template v-if="phase === 'inspectionPre'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="readProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- センサ感度検査成功表示 -->
      <template v-if="phase === 'successSensitivity'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="closeProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 各エラー表示 -->
      <template v-if="phase === 'failedSystemFailure' || phase === 'failedTimeout' || phase === 'failedPoint'
                      || phase === 'conditionFailed' || phase === 'failedSensitivity'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="closeProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 汎用エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>