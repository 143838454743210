import api from '@/common/api';
import config from '../config';
import handler from '../util/apiResultValidator';
import util from '@/share/util/utils';
import saml from '@/share/util/saml';

export default {
  namespaced: true,

  state: {
    language: util.getWindowNavigatorLanguage(config.LANGUAGE_LIST),
    versionInfo: {
      version: '',
      displayVersion: config.VERSION_UNKNOWN_DISPLAY,
      copyright: util.isChinaResion() ? "Copyright © 斯巴鲁汽车（中国）有限公司" : "Copyright © SUBARU CORPORATION 2020 All Rights Reserved.",
      isMaintenance: false,
      minTime: '',
      maxTime: ''
    },
    samlAccountList: [],
    techHqAccount: {},
    isMaintenance: false,
    maintenanceTimeRange: {},
    domainInfo: {
      type: config.SBR_DOMAIN,
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    isExternalError: false,
    securityNumber: "11010502044169",
    icpNumber: "05071176号-1",
  },

  mutations: {

    setLanguage(state, payload) {
      state.language = payload.language;
    },

    setSystemVersion(state, payload) {
      state.versionInfo = {
        version: payload.version,
        displayVersion: payload.displayVersion,
        copyright: payload.copyright
      };
      // バージョン情報取得時にメンテナンス中状態を設定する
      state.isMaintenance = payload.isMaintenance;
      state.maintenanceTimeRange = { min: payload.minTime, max: payload.maxTime };
    },

    samlAccountList(state, payload) {
      state.samlAccountList = payload;
    },

    setTechHqAccount(state, payload) {
      state.techHqAccount = payload;
    },

    setDomainInfo(state, payload) {
      state.domainInfo = payload;
    },

    setExternalError(state, payload) {
      state.isExternalError = payload;
    },

  },

  actions: {

    // 内部に言語情報を設定する(サーバーには更新しない)
    setLanguage(context, payload) {
      let language = payload.language;
      if (!language) {
        language = 'en';
      }
      const param = { language: language };
      context.commit('setLanguage', param);
    },

    async updateLanguage(context, payload) {

      const language = payload.language;
      const userId = payload.userId;

      if (context.state.language === language) {
        return;
      }

      // 利用者 ID が取得できていない場合でも画面の表示言語は変更する
      const param = { language: language };
      context.commit('setLanguage', param);
      if (userId) {
        await api.postCall(config.ADMIN, '/users/' + userId + '/language', param);
      }
    },

    async getSystemInfo(context, payload) {

      await Promise.all([
        context.dispatch('setSystemVersion'),
        context.dispatch('setExternalEndpointList', payload)
      ]);
    },

    async setSystemVersion(context) {

      const param = {
        version: '',
        displayVersion: config.VERSION_UNKNOWN_DISPLAY,
        copyright: util.isChinaResion() ? "Copyright © 斯巴鲁汽车（中国）有限公司" : "Copyright © SUBARU CORPORATION 2020 All Rights Reserved.",
        isMaintenance: false,
        minTime: '',
        maxTime: ''
      };

      // バージョン情報は取得が失敗してもエラーとはしない 
      try {
        // バージョン取得APIに変更する 
        const response = await api.getParamCall(config.MAINT, '/systemVersion');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 成功した場合
            // responseから取得したバージョンに変更する 
            param.version = response.data.version;
            let displayVersion = response.data.version;
            if (util.isChinaResion()) {
              displayVersion = displayVersion + " CN";
            }
            param.displayVersion = displayVersion;

            context.commit('setSystemVersion', param);
          },
          (result) => {
            // メンテナンス中のチェックだけ判定する 
            if (result.causeType === handler.causeTypes.maintenanceError) {
              param.isMaintenance = true;
              param.minTime = response.data.details.minimum_time;
              param.maxTime = response.data.details.maximum_time;
            }
            context.commit('setSystemVersion', param);
          }, null, false);
      } catch (error) {
        context.commit('setSystemVersion', param);
      }
    },

    // サーバから外部連携のリスト情報を取得する 
    async setExternalEndpointList(context, payload) {
      // SAML連携情報を取得する (SAML連携情報は取得に失敗してもエラーとしない)
      try {
        const params = {
          service: payload.type
        };
        const response = await api.getParamCall(config.ADMIN, '/authorizationEndpoints', params);
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 成功した場合
            const samlAccountList = [];
            response.data.endPoints.forEach(system => {
              // "name" または "url" が空の場合は外部連携のボタンを非表示
              if (system.name && system.url && system.domain) {
                let sloLogoutUrl = null;
                // 中国の場合はドメイン名は１つなので、ドメインチェックは実施しない
                if (util.isChinaResion()) {
                  sloLogoutUrl = system.sign_out_url;
                  const data = {
                    needSingleLogout: true,
                    url: system.url,
                    sloLogoutUrl: sloLogoutUrl,
                    name: system.name[payload.language],
                    names: system.name,
                    provider: 'SUBARU'
                  };
                  samlAccountList.push(data);
                } else {
                  if (system.domain.toLowerCase() === payload.domain.toLowerCase()) {
                    const providerName = saml.getProviderFromIdentity(system.url);
                    // 完全ログアウト用の URL を生成
                    const baseLogout = 'https://' + system.cognito_domain + '/logout?client_id=' + system.app_client_id;
                    const logoutUrl = system.sign_out_url_list.length > 0 ? '&logout_uri=' + system.sign_out_url_list[0] : '';
                    sloLogoutUrl = baseLogout + logoutUrl;
                    const data = {
                      needSingleLogout: system.enable_sign_out_flow,
                      provider: providerName,
                      url: system.url,
                      sloLogoutUrl: sloLogoutUrl,
                      name: system.name[payload.language],
                      names: system.name
                    };
                    // 技本ユーザー（機能評価者）連携ボタンはログイン画面に表示せず、アカウント情報は別途保持
                    if (system.url.toLowerCase().indexOf('subaru-tech') !== -1) {
                      context.commit('setTechHqAccount', data);
                    } else {
                      samlAccountList.push(data);
                    }
                  }
                }
              }
            });
            context.commit('samlAccountList', samlAccountList);
          },
          () => {
            // 失敗した場合、ログイン画面のまま何もしない
            context.commit('samlAccountList', []);
          }, null, false);
      } catch (error) {
        // console.log(error);
        context.commit('samlAccountList', []);
      }
    },

    // 内部にドメイン情報を設定する
    setDomainInfo(context, payload) {
      context.commit('setDomainInfo', payload);
    },

    // ログイン画面の表示有無を設定する 
    setExternalError(context, payload) {
      context.commit('setExternalError', payload);
    },

    // eslint-disable-next-line no-unused-vars
    extendSessionTimer(context) {
      try {
        api.getParamCall(config.MAINT, '/systemVersion');
      } catch (err) {
        // noop
      }
    }

  }
};