<!-- VIN登録ダイアログ -->
<b-modal v-model="modal_requirement_051" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' || phase === 'confirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'registeredFailed' || phase === 'unregisteredFailed'
      || phase === 'communicationFailed' || phase === 'mismatchFailed' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 開始(確認)、確認、正常終了 -->
        <template v-if="phase === 'start' || phase === 'confirmation' || phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(VIN登録済み表示、VIN未登録表示、通信異常表示、VIN不一致表示) -->
        <template v-if="phase === 'registeredFailed' || phase === 'unregisteredFailed'
        || phase === 'communicationFailed' || phase === 'mismatchFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              displayInfo }}</div>
          </div>
        </template>
        <!-- 異常終了(共通エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!--開始(確認)-->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="read" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!--確認-->
      <template v-if="phase === 'confirmation'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="write" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!--成功、異常終了-->
      <template v-if="phase !== 'start' && phase !== 'confirmation'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>