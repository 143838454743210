<b-modal v-model="modal_requirement_052" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'list' || phase === 'nodata'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 一覧 -->
        <template v-if="phase === 'list'">
          <div class="row ml-1">
            <div class="ml-2">
              <label v-html="$t(`individual.label_current_value`, [''])"></label>
            </div>
          </div>
          <div class="row ml-1">
            <div class="ml-2">
              <label v-html="$t(`individual.label_ig_on_count`)"></label>
            </div>
            <div class="ml-2">
              <label>{{ currentCnt }}</label>
            </div>
            <div class="ml-2">
              <label v-html="$t(`individual.label_absolute_time`)"></label>
            </div>
            <div class="ml-2">
              <label>{{ absoluteTime }}</label>
            </div>
          </div>
          <div class="no-gutters">
            <custom-table useStripeStyle rowSelect hasTabs :items="items" :columns="tableColumns"
              class="mt-2 ml-2" type="simple" @rowClicked="rowClick" usePopup useSort currentSortkey="history" initSortDir="asc"/>
          </div>
        </template>
        <!-- データ無 -->
        <template v-if="phase === 'nodata'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" v-html="$t(`individual.label_nodata`)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>


  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 一覧 -->
      <template v-if="phase === 'list'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading || !isSaveButton" @click="readProcess"
          class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <!-- データ無 or 異常終了 -->
      <template v-if="phase === 'nodata' || phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>