<!-- 可変コンプレッサ慣らし運転 -->
<b-modal v-model="modal_requirement_104" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'standby'|| phase === 'execution'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'start' || phase === 'confirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'caution'">
        {{ $t('message.common_title_warning') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 開始確認, 警告表示, 実行, 実行監視, 停止（成功） -->
        <template
          v-if="phase === 'start' || phase === 'caution' || phase === 'confirmation' || phase === 'execution' || phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
              v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 開始確認 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitProcess" class="mx-2">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="clickYesStart" class="mx-2">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>
      <!-- 警告表示 -->
      <template v-if="phase === 'caution'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="clickOKCaution" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 実行 -->
      <template v-if="phase === 'confirmation'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="execute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止（成功）, 通信エラー -->
      <template v-if="phase === 'success' || phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>