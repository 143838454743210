<b-modal
  v-model="modal_special_requirement_ES3"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t(`message.common_title_success`) }}
      </template>
      <template v-if="phase === 'timeout' ||
                      phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行監視 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_please_wait_ES3`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 成功表示 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_success_ES3')"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- タイムアウト -->
        <template v-if="phase === 'timeout'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_timeout_ES3`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 実行監視 -->
      <template v-if="phase === 'start'">
      </template>
      <!-- 成功表示 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="successProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- タイムアウト -->
      <template v-if="phase === 'timeout'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>