<b-modal
  v-model="modal_requirement_115"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' || phase === 'saveData'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'inputData' ">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'registered' || phase === 'warning' ">
        {{ $t('message.common_title_warning') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'overSpeedError' || phase === 'selectError' || phase === 'fraudError' || phase === 'appliedModelUndefined' || phase === 'appliedModelAnomaly' || phase === 'error' ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- バリアントデータ登録済表示 -->
        <template v-if="phase === 'registered'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 警告表示 -->
        <template v-if="phase === 'warning'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_2`) }}</div>
          </div>
        </template>
        <!-- アプライドモデル・オプションコード入力表示 -->
        <template v-if="phase === 'inputData'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="w-100 mb-4">
                <div class="text-center work-support-message">{{ 
                  $t('individual.label_input_applied_op') }}</div>
                <table class="box-margin-right box-margin-top work-support-message" style="width:400px;">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">{{ 
                        $t(`${i18nWsKey}.REQ_WS_115_TXT_4`) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="appliedModel"
                            :state="validateAppliedModel && !validateAppliedModel.valid" type="text" size="sm"
                            autocomplete="off"></b-form-input>
                          <b-form-invalid-feedback v-if="validateAppliedModel">{{ 
                            validateAppliedModel.msg }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top">{{ 
                        $t(`${i18nWsKey}.REQ_WS_115_TXT_6`) }}</td><td>
                        <b-form-group>
                          <b-form-input v-model="optionCode" :state="validateOptionCode && !validateOptionCode.valid" type="text" size="sm"
                            autocomplete="off"></b-form-input>
                            <b-form-invalid-feedback v-if="validateOptionCode">{{ 
                              validateOptionCode.msg }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="work-support-message"  style="white-space: break-spaces;" v-html="$t(`individual.message_input_option_code_information`)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- データ書込み -->
        <template v-if="phase === 'saveData'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_7`) }}</div>
          </div>
        </template>
        <!-- 停止 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="w-100">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t('individual.label_vehicle_information') }}</div>
              </div>
              <div class="text-left align-middle mt-3">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t('individual.label_table_applied_model') }}</div>
              </div>
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table
                  id="cstm-table"
                  useStripeStyle
                  hasTabs
                  useSort
                  initSortDir="acs"
                  currentSortkey="itemName"
                  :items="modelBodyItems"
                  :columns="modelTableColumns"
                  type="simple"
                >
                </cstm-table>
              </div>
              <div class="text-left align-middle mt-2">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_table_grade`) }}</div>
              </div>
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table
                  id="cstm-table"
                  useStripeStyle
                  hasTabs
                  useSort
                  initSortDir="acs"
                  currentSortkey="itemName"
                  :items="gradeBodyItems"
                  :columns="gradeTableColumns"
                  type="simple"
                >
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 車輪速超過表示 -->
        <template v-if="phase === 'overSpeedError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_9`) }}</div>
          </div>
        </template>
        <!-- パラメータ選択不可表示 -->
        <template v-if="phase === 'selectError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_10`) }}</div>
          </div>
        </template>
        <!-- バリアントデータ不正表示 -->
        <template v-if="phase === 'fraudError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_11`) }}</div>
          </div>
        </template>
        <!-- アプライドモデル未定義表示 -->
        <template v-if="phase === 'appliedModelUndefined'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_12`) }}</div>
          </div>
        </template>
        <!-- アプライドモデル異常表示 -->
        <template v-if="phase === 'appliedModelAnomaly'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_115_TXT_13`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- バリアントデータ登録済表示 -->
      <template v-if="phase === 'registered'">
        <b-button size="sm" variant="primary" @click="onRegisteredOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 警告表示 -->
      <template v-if="phase === 'warning'">
        <b-button size="sm" variant="outline-primary" @click="onWarningNoClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onWarningOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('individual.common_button_yes') }}
        </b-button>
      </template>
      <!-- アプライドモデル・オプションコード入力表示 -->
      <template v-if="phase === 'inputData'">
        <b-button size="sm" variant="outline-primary" @click="onInputDataNoClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onInputDataOkClicked" class="mx-2" :disabled="isLoading">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止 (正常終了) -->
      <template v-if="phase === 'success'">
        <b-button size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 通信エラー -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2">{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>