<b-modal v-model="modal_requirement_060" :size="modalSize" :header-bg-variant="headerColor" 
header-text-variant="light" no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'listView' || phase === 'nodata'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'detailedFd'">
        {{ $t('message.common_title_infomation') }}
      </template>
      <template v-if="phase === 'projectData'">
        {{ $t(`individual.label_input`) }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期 -->
        <template v-if="phase === 'start'"></template>
        <!-- 一覧表示 -->
        <template v-if="phase === 'listView'">
          <custom-table useStripeStyle hasTabs :items="displayInfo" :columns="tableColumns" useSort currentSortkey="status" initSortDir="asc"
            class="mt-2 ml-2" type="simple" usePopup rowSelect @rowButtonClicked="rowButtonClickEvent" >
            <template v-slot:rowButton >{{ $t(`individual.button_fd`) }}</template>
          </custom-table>
        </template>
        <!-- 履歴データ無し表示 -->
        <template v-else-if="phase === 'nodata'">
          {{ $t(`individual.label_history`) }}
        </template>
        <!-- 詳細FD表示 -->
        <template v-if="phase === 'detailedFd'">
          <div class="row ml-1">
            <div class="ml-2">
              <label>{{ statusLabel }}</label>
            </div>
            <div class="ml-2">
              <label style="font-weight: bold;">{{ statusValue }}</label>
            </div>
          </div>
          <custom-table id="detailedFd" useStripeStyle hasTabs :items="displayFdInfo" :columns="tableFdColumns"
          useSort currentSortkey="did" initSortDir="asc" class="mt-2 ml-2" type="simple" :height="1000" usePopup >
          </custom-table>
        </template>
        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'projectData'">
          <div class="col-12">
            <b-form-group>
              <div class="row ml-1">
                <div class="col-3">
                  <label>{{ $t('individual.label_save_name') }}
                    <span class="input-attention-required ">{{ $t("message.alert_vinz_input_required") }}</span>
                  </label>
                </div>
                <div class="col-9">
                  <b-form-group>
                    <b-form-input type="text" v-model="dataName" size="sm" :state="isValidate.dataName.valid"></b-form-input>
                    <b-form-invalid-feedback>{{ $t(isValidate.dataName.msg, [isValidate.dataName.length]) }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </b-form-group>
            <div class="row ml-1">
              <div class="col-3">
                <label>{{ $t(`individual.label_system`) }}</label>
              </div>
              <div class="col-9">
                <label>
                  <span>{{ system }}</span>
                </label>
              </div>
            </div>
            <div class="row ml-1">
              <div class="col-3">
                <label>{{ $t(`individual.label_detail`) }}</label>
              </div>
              <div class="col-9">
                <label>
                  <span>{{ contents }}</span>
                </label>
              </div>
            </div>
            <div class="row ml-1">
              <div class="col-3">
                <label>{{ $t(`individual.label_date`) }}</label>
              </div>
              <div class="col-9">
                <label>
                  <span>{{ date }}</span>
                </label>
              </div>
            </div>
            <b-form-group>
              <div class="row ml-1">
                <div class="col-4">
                  <label>{{ $t(`individual.label_memo`) }}</label>
                </div>
              </div>
              <b-form-group>
                <div class="row ml-3">
                  <b-form-textarea type="text" v-model="memo" :state="isValidate.memo.valid" size="sm" rows="5" />
                  <b-form-invalid-feedback>{{ $t(isValidate.memo.msg, [isValidate.memo.length]) }}</b-form-invalid-feedback>
                </div>
              </b-form-group>
            </b-form-group>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'listView'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="saveButtonClickEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'nodata'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="Secondary" @click="saveButtonClickEvent" class="btn btn-secondary" disabled>
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'detailedFd'">
        <b-button size="sm" variant="primary" @click="backEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'projectData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSaveEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>