<b-modal v-model="modal_requirement_137" size="lg" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 初期処理,実行監視 -->
      <template v-if="phase === 'initialWaiting' || phase === 'executing'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 実行 -->
      <template v-if="phase === 'executionConfirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー -->
      <template v-if="phase === 'executionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>
  <!-- 文言表示 -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行 -->
        <template v-if="phase === 'executionConfirmation'">
          <div style="max-height: 265px; overflow-y: auto;" class="overflow-y: scroll bottom">
            <div>
              <div class="row no-gutters" ref="tableHeader">
                <div class="col-auto">
                  <slot name="subButtonContainer"></slot>
                </div>
                <div class="col pagination justify-content-end tabs-bottom">
                  <slot name="contentRightEnd"></slot>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-sm overflow-auto simple-table">
                  <tr v-if="columns">
                    <th v-for="(column, i) in columns" :key="i">
                      <span>{{ $t(column.label) }}</span>
                    </th>
                  </tr>
                  <tr v-for="(data, index) in (items)" :key="index">
                    <td v-for="(column, i) in columns" :key="i" v-bind:class="getTdStyle(data.bgStyle, index)">
                      <span v-bind:class="getColumnClasses(data, column.name)" v-html="data[column.name]">
                      </span>
                      <div v-if="column.useCheckbox">
                        <input type="checkbox" @click="checkboxClicked(index)" v-model="isChecked[index]"
                          v-bind:disabled="isDisabled[index]"
                          v-bind:class="isDisabled[index]?'checkbox-disabled':'checkbox'" class="checkbox">
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行監視 -->
        <template v-if="phase === 'executing'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <label class="topleft">
                  {{ $t(`${i18nWsKey}.REQ_WS_137_TXT_1`) }}
                </label>
              </div>
            </div>
          </div>
        </template>
        <!-- 停止 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_137_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'executionFailed'">
          <div class="w-100">
            <div class="work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_137_TXT_3`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>
  <!-- フッター -->
  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 実行 -->
      <template v-if="phase === 'executionConfirmation'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || isButtonDisabled" variant="primary" @click="upload" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="downloadFile" class="mx-2">
          {{ $t('message.button_vehicle_system_download_zip') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー -->
      <template v-if="phase === 'executionFailed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>