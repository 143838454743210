<b-modal v-model="modal_requirement_073" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- マスタデータ入力画面 -->
      <template v-if="phase === 'inputFile'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中 -->
      <template v-if="phase === 'inProgress'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 正常終了画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- マスタデータチェックエラー, 異常終了表示画面,
        キャリブレーション結果エラー表示画面, 完了処理エラー表示画面,
        通信エラー画面, エラー画面 -->
      <template v-if="(phase === 'inputFileError') || (phase === 'errorFinish') || 
        (phase === 'resultError') || (phase === 'finishProcessError') ||
        (phase === 'communicationError') || (phase === 'error')">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- マスタデータチェックエラー -->
        <template v-if="phase === 'inputFileError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" 
                  v-html="$t('individual.label_input_file_error_message')"></div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'inProgress'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.label_in_progress`) }}</div>
          </div>
        </template>
        <!-- 正常終了画面 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort initSortDir="asc"
                  :items="items" :columns="systemTableColumns" type="simple">
                </cstm-table>
              </div>
            </div>
          </div>
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" 
                  v-html="$t('individual.label_calibration_success_message')"></div>
          </div>
        </template>
        <!-- 異常終了表示画面 -->
        <template v-if="phase === 'errorFinish'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort initSortDir="asc"
                  :items="items" :columns="systemTableColumns" type="simple">
                </cstm-table>
              </div>
            </div>
          </div>
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(errorMessage) }}</div>
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.label_calibration_instruction`) }}</div>
          </div>
        </template>
        <!-- キャリブレーション結果エラー表示画面 -->
        <template v-if="phase === 'resultError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.label_read_error`) }}</div>
          </div>
        </template>
        <!-- 完了処理エラー表示画面 -->
        <template v-if="phase === 'finishProcessError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" 
                  v-html="$t('individual.label_process_error')"></div>
          </div>
        </template>
        <!-- 通信エラー画面 -->
        <template v-if="phase === 'communicationError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.label_communication_error`) }}</div>
          </div>
        </template>
        <!-- エラー画面 -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- マスタデータチェックエラー画面 -->
      <template v-if="phase === 'inputFileError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onInputFileErrorOkClicked" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正常終了画面 -->
      <template v-if="phase === 'success'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了表示画面 -->
      <template v-if="phase === 'errorFinish'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorFinishSaveClicked" class="mx-2">{{
          $t('message.common_button_save') }}
        </b-button>
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorFinishBackClicked" class="mx-2">{{
          $t('message.common_button_back') }}
        </b-button>
      </template>
      <!-- キャリブレーション結果エラー表示画面 -->
      <template v-if="phase === 'resultError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onResultErrorOkClicked" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 完了処理エラー表示画面 -->
      <template v-if="phase === 'finishProcessError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onFinishProcessErrorOkClicked"
          class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 通信エラー画面 -->
      <template v-if="phase === 'communicationError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onCommunicationErrorOkClicked"
          class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー画面 -->
      <template v-if="phase === 'error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>